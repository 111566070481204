<template>
  <v-navigation-drawer v-model="isNavDrawerOpen" app>
    <v-list nav dense>
      <v-list-item-group>
        <v-list-item
          v-for="item in drawerLaunchItems"
          :key="item.title"
          @click="navigate(item)"
        >
          <v-list-item-icon>
            <v-icon :color="item.color">
              {{ item.icon }}
            </v-icon>
          </v-list-item-icon>
          <v-list-item-title>{{ item.title }}</v-list-item-title>
        </v-list-item>
      </v-list-item-group>
    </v-list>
  </v-navigation-drawer>
</template>

<script>
import { watch, ref } from 'vue'
import { useRouter } from 'vue2-helpers/vue-router'
import { feature } from '@/use/Constants'
import {
  retrievePortalSettings,
  portalSettings
} from '@/services/PortalSettingsCache'

export default {
  name: 'NavDrawer',
  props: {
    state: {
      type: Number,
      default: 0
    }
  },
  setup(props) {
    const isNavDrawerOpen = ref(true)

    const router = useRouter()
    const drawerLaunchItems = ref([])

    watch(
      () => props.state,
      () => {
        isNavDrawerOpen.value = !isNavDrawerOpen.value
      }
    )

    const initDialog = async () => {
      const items = []
      await retrievePortalSettings()
      items.push(feature['home'])
      items.push(feature['profile'])
      if (portalSettings.value.ShowTransactions) {
        items.push(feature['billing'])
      }
      if (portalSettings.value.ShowServices) {
        items.push(feature['services'])
      }

      drawerLaunchItems.value.splice(
        0,
        drawerLaunchItems.value.length,
        ...items
      )
    }

    const navigate = item => {
      router.push({ name: item.routerName })
    }

    initDialog()

    return {
      feature,
      isNavDrawerOpen,
      drawerLaunchItems,
      navigate
    }
  }
}
</script>

<style lang="scss" scoped></style>
