const populateMonthSelectList = () => {
  return [...Array(12).keys()].map(value => {
    return { Text: String(value + 1).padStart(2, '0'), Value: value + 1 }
  })
}

const populateYearSelectList = () => {
  const today = new Date()

  const yearSelectList = [...Array(8).keys()].map(value => {
    const year = today.getFullYear() + value
    return { Text: year.toString(), Value: year }
  })

  return yearSelectList
}

export { populateMonthSelectList, populateYearSelectList }
