<template>
  <div>
    <v-card :width="width">
      <v-card-title>
        <DialogTitle v-if="!isSummary" :dialogName="$options.name">
          <div>Treatments</div>
          <div class="subtitle-1">For Site: {{ siteDescription }}</div>
        </DialogTitle>
        <div v-else class="text-subtitle-1 blue--text">
          Treatments
        </div>
      </v-card-title>
      <v-card-text>
        <v-data-table
          v-if="headers && info && info.length > 0"
          dense
          :items-per-page="10"
          :footer-props="{
            'show-first-last-page': true
          }"
          :headers="headers"
          :items="info"
          class="elevation-1"
        >
          <template v-slot:item.WhenAdded="{ item }">
            {{ toLocaleDateTimeString(item.WhenAdded) }}
          </template>
          <template v-slot:item.IsBillable="{ item }">
            <v-icon v-if="item.IsBillable" color="primary">
              {{ icon.lineItemIsSelected }}
            </v-icon>
          </template>
        </v-data-table>
      </v-card-text>
      <v-card-actions>
        <v-btn v-if="!isSummary" @click="closeButtonClick" color="success"
          >Close</v-btn
        >
      </v-card-actions>
    </v-card>
  </div>
</template>

<script>
import { ref, watch } from 'vue'
import { icon } from '@/use/Constants'
import { host } from '@/services/HostAPI'
import { toLocaleDateTimeString } from '@/use/DateFormatter'
export default {
  name: 'TreatmentList',
  props: {
    siteId: {
      type: String,
      required: true
    },
    siteDescription: {
      type: String,
      required: true
    },
    scheduledServiceId: {
      type: String,
      default: null
    },
    isDialogOpen: {
      type: Boolean,
      default: false
    },
    isSummary: {
      type: Boolean,
      default: false
    },
    width: {
      type: Number,
      default: 6000
    }
  },
  setup(props, context) {
    watch(
      () => props.isDialogOpen,
      newVal => {
        newVal && initDialog()
      }
    )

    const info = ref()
    const headers = [
      { value: 'WhenAdded', text: 'Date' },
      { value: 'Details', text: 'Details' },
      { value: 'Notes', text: 'Notes' }
    ]

    const selectedTreatmentId = ref()

    const initDialog = async () => {
      // const treatmentTypeList = await getTreatmentTypeList()
      const treatmentInfoList = await getTreatmentList(
        props.siteId,
        props.scheduledServiceId
      )
      info.value = treatmentInfoList.map(item => {
        return {
          TreatmentId: item.TreatmentId,
          WhenAdded: item.WhenAdded,
          Details: getDetails(item.ItemList),
          Notes: item.Notes
        }
      })
    }

    const getDetails = itemList => {
      return itemList
        .filter(item => item.TreatmentDetailId != null)
        .map(
          item =>
            `${item.Description}/${item.UnitDescription}: ${item.UnitsAdded}${
              item.IsBillable ? ' (bill)' : ''
            }`
        )
        .join(', ')
    }

    const getTreatmentList = async (siteId, scheduledServiceId) => {
      const rq = {
        Criteria: { SiteId: siteId, ScheduledServiceId: scheduledServiceId }
      }
      const rs = await host.treatment.list(rq)
      return rs.InfoList
    }

    const closeButtonClick = () => {
      context.emit('close')
    }

    initDialog()

    return {
      info,
      headers,
      closeButtonClick,
      toLocaleDateTimeString,
      selectedTreatmentId,
      icon
    }
  }
}
</script>

<style lang="scss" scoped></style>
