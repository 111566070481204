<template>
  <div>
    <v-card class="mt-2">
      <v-card-text>
        <div class="d-flex">
          <div>
            <v-btn small @click="measureListClick">
              <v-icon :color="feature.serviceables.color">
                {{ icon.measure }}
              </v-icon>
              Measures
            </v-btn>
            <v-btn class="ml-2" small @click="treatmentListClick">
              <v-icon :color="feature.serviceables.color">
                {{ icon.treatment }}
              </v-icon>
              Treatments
            </v-btn>
          </div>
        </div>
      </v-card-text>
    </v-card>

    <v-dialog v-model="showMeasureListDialog" width="1200">
      <MeasureList
        :isDialogOpen="showMeasureListDialog"
        :siteId="cardData.SiteId"
        :siteDescription="cardData.Description"
        @close="measureListCloseEvent"
      />
    </v-dialog>

    <v-dialog v-model="showTreatmentListDialog" width="1200">
      <TreatmentList
        :isDialogOpen="showTreatmentListDialog"
        :siteId="cardData.SiteId"
        :siteDescription="cardData.Description"
        @close="treatmentListCloseEvent"
      />
    </v-dialog>
  </div>
</template>

<script>
import { ref } from 'vue'
import { icon, feature } from '@/use/Constants'
import MeasureList from '@/components/Site/MeasureList'
import TreatmentList from '@/components/Site/TreatmentList'
export default {
  name: 'ServiceablesCard',
  props: {
    cardData: {
      type: Object,
      required: true
    }
  },
  components: { MeasureList, TreatmentList },
  setup() {
    const selectedServiceableId = ref()
    const selectedServiceableTypeId = ref()
    const selectedDescription = ref()
    const showMeasureListDialog = ref(false)

    const showTreatmentListDialog = ref(false)

    const serviceableItemClick = serviceable => {
      selectedServiceableId.value = serviceable.ServiceableId
      selectedServiceableTypeId.value = null
      selectedDescription.value = serviceable.Description
    }

    const measureListClick = () => {
      showMeasureListDialog.value = true
    }

    const measureListCloseEvent = () => {
      showMeasureListDialog.value = false
    }

    const treatmentListClick = () => {
      showTreatmentListDialog.value = true
    }

    const treatmentListCloseEvent = () => {
      showTreatmentListDialog.value = false
    }

    return {
      feature,
      serviceableItemClick,
      selectedServiceableId,
      selectedServiceableTypeId,
      selectedDescription,
      showMeasureListDialog,
      measureListClick,
      measureListCloseEvent,
      treatmentListClick,
      showTreatmentListDialog,
      treatmentListCloseEvent,
      icon
    }
  }
}
</script>

<style lang="scss" scoped></style>
