<template>
  <div v-if="cardData">
    <PageTitle
      featureName="billing"
      functionName="Details"
      :target="cardData.Name"
      :pageName="$options.name"
    />
    <div class="d-flex flex-wrap">
      <v-card class="ma-2 indigo lighten-5" width="400">
        <v-card-title class="d-flex justify-space-between">
          <div>{{ cardData.Name }}</div>
          <v-tooltip top>
            <template v-slot:activator="{ on, attrs }">
              <v-btn icon class="d-flex" @click="navigateToCustomer">
                <v-icon :color="feature.profile.color" v-bind="attrs" v-on="on">
                  {{ icon.customer }}
                </v-icon>
              </v-btn>
            </template>
            <span>View Customer</span>
          </v-tooltip>
        </v-card-title>
        <v-card-subtitle :class="statusClass">
          <div>
            <span>{{ cardData.StatusDescription }}</span>
            <span class="float-right black--text mr-2">
              {{ cardData.Code }}
            </span>
          </div>
        </v-card-subtitle>
        <v-card-text>
          <div v-if="cardData.AlertType">
            <span>Alert: </span>
            <span class="red--text font-weight-bold">
              {{ cardData.AlertType }} / {{ cardData.AlertText }}
            </span>
          </div>
          <BillingSummaryCard
            disableBillingLink
            :cardData="cardData"
            :hideSummaryLink="true"
            @update="billingSummaryCardUpdateEvent"
          />
        </v-card-text>
      </v-card>
      <div>
        <v-card class="ma-2 indigo lighten-5" width="400">
          <v-card-text>
            <OnlinePaymentCard :cardData="cardData" />
          </v-card-text>
        </v-card>
        <v-card
          v-if="portalSettings && portalSettings.ShowTransactionReports"
          class="ma-2"
          width="400"
          height="80"
        >
          <v-card-text>
            <v-card-actions>
              <v-btn
                small
                color="primary"
                class="ml-2"
                @click="statementReportButtonClick"
              >
                <v-icon class="mr-2">{{ icon.report }}</v-icon>
                Statement
              </v-btn>
              <v-btn
                small
                color="primary"
                class="ml-2"
                @click="transactionDetailReportButtonClick"
              >
                <v-icon class="mr-2">{{ icon.report }}</v-icon>
                Transaction Report
              </v-btn>
            </v-card-actions>
          </v-card-text>
        </v-card>
      </div>
    </div>
    <v-card class="mx-2" max-width="1000">
      <v-card-title>
        <div class="text-subtitle-1 blue--text">
          Transactions
        </div></v-card-title
      >
      <v-data-table
        dense
        :items-per-page="10"
        :footer-props="{
          'items-per-page-options': items_per_page,
          'show-first-last-page': true
        }"
        :headers="headers"
        :items="dataTable.LineItemList"
        :options.sync="options"
        :sort-by.sync="sortBy"
        :sort-desc.sync="sortDesc"
        :server-items-length="dataTable.TotalRecords"
        :loading="loading"
        @click:row="selectRow"
        class="elevation-1"
        ><template v-slot:item.BusinessDate="{ item }"
          ><span>{{ new Date(item.BusinessDate).toLocaleDateString() }}</span>
        </template>
        <template v-slot:item.DebitAmount="{ item }"
          ><span>{{ toMoney(item.DebitAmount) }}</span>
        </template>
        <template v-slot:item.CreditAmount="{ item }"
          ><span>{{ toMoney(item.CreditAmount) }}</span>
        </template>
        <template v-slot:item.RunningAmount="{ item }"
          ><span>{{ toMoney(item.RunningAmount) }}</span>
        </template>
      </v-data-table>
    </v-card>

    <v-dialog v-model="showInvoiceDialog" width="1000" persistent>
      <Invoice
        :isDialogOpen="showInvoiceDialog"
        :customerId="cardData.CustomerId"
        :invoiceId="selectedTransactionId"
        @close="hideInvoiceDialog(false)"
        @update="hideInvoiceDialog(true)"
      />
    </v-dialog>
  </div>
</template>

<script>
import { ref, watch, computed } from 'vue'
import { useRouter } from 'vue2-helpers/vue-router'
import { host } from '@/services/HostAPI'
import { icon, feature } from '@/use/Constants'
import { sessionState } from '@/store/UserSession'
import BillingSummaryCard from '@/components/Billing/BillingSummaryCard'
import OnlinePaymentCard from '@/components/OnlinePayment/OnlinePaymentCard'
import Invoice from '@/components/Billing/Invoice'
import reportInfo from '@/constants/reports'
import ReportService from '@/services/ReportService'
import { toMoney } from '@/use/NumberFormatter'
import {
  retrievePortalSettings,
  portalSettings
} from '@/services/PortalSettingsCache'

export default {
  name: 'BillingDetails',
  components: {
    BillingSummaryCard,
    OnlinePaymentCard,
    Invoice
  },
  setup() {
    const router = useRouter()

    const showInvoiceDialog = ref(false)
    const cardData = ref(null)
    const selectedTransactionId = ref(null)
    const loading = ref(true)
    const options = ref({})
    const sortBy = ref([])
    const sortDesc = ref([])
    const dataTable = ref({})
    const items_per_page = [10, 15, 20, 50]
    const firstTime = ref(true)

    watch(
      () => options.value,
      () => {
        loadDataTable().catch(e => alert('error: ' + e.message))
      },
      { deep: true }
    )

    const statusClass = computed(() => {
      return (
        cardData.value && feature.profile.statusColors[cardData.value.Status]
      )
    })

    const headers = [
      { value: 'BusinessDate', text: 'Date' },
      { value: 'InvoiceNumber', text: 'Invoice' },
      { value: 'TranCodeDesc', text: 'Type' },
      { value: 'Reference', text: 'Reference' },
      { value: 'DebitAmount', text: 'Charge', align: 'right' },
      { value: 'CreditAmount', text: 'Credit', align: 'right' },
      { value: 'RunningAmount', text: 'Running', align: 'right' }
    ]

    const load = async () => {
      const rq = {
        Id: sessionState.value.UserInfo.CustomerId,
        RetrieveSites: false
      }
      const rs = await host.customer.card(rq)
      cardData.value = rs.Info
    }

    const initDialog = () => {
      retrievePortalSettings()
      load()
    }

    const loadDataTable = async () => {
      loading.value = true
      const { sortBy, sortDesc, page, itemsPerPage } = options.value
      const rq = {
        CustomerId: sessionState.value.UserInfo.CustomerId,
        IncludeFutureTransactions:
          portalSettings.value.IncludeFutureTransactions,
        BalanceForwardBeforeDays: portalSettings.value.BalanceForwardBeforeDays,
        Pagination: {
          Page: page,
          RowsPerPage: itemsPerPage,
          SortBy: sortBy.length > 0 ? sortBy[0] : 'Date',
          IsDescending: sortDesc.length > 0 ? sortDesc[0] : false
        }
      }
      const rs = await host.billing.loadDetailsDataTable(rq)
      if (firstTime.value) {
        firstTime.value = false
        dataTable.value.TotalRecords = rs.TotalRecords
        if (options.value.page != rs.TotalPages) {
          options.value.page = rs.TotalPages
        } else {
          dataTable.value = rs
        }
      } else {
        dataTable.value = rs
      }
      loading.value = false
    }

    const refresh = () => {
      load()
      loadDataTable()
    }

    const statementReportButtonClick = () => {
      ReportService.emitShowReportDialog({
        ReportId: reportInfo.report.statement.reportId,
        ParameterList: [
          { Name: 'EmailOnly', Value: 'False' },
          { Name: 'StartAccountCode', Value: cardData.value.Code },
          { Name: 'IncludeZeroBalances', Value: 'True' },
          { Name: 'IncludeCreditBalances', Value: 'True' },
          { Name: 'OneOnly', Value: 'True' },
          { Name: 'EmailOnly', Value: cardData.value.PrintNoStatement }
        ],
        ParameterHideList: [
          'DetailDate',
          'EndDate',
          'MonthlyMessage',
          'OverdueMessage30',
          'OverdueMessage60',
          'OverdueMessage90',
          'WhenDueMessage',
          'EmailOnly',
          'EmailSubject',
          'EmailBody',
          'BillingGroupId',
          'StartAccountCode',
          'OneOnly',
          'OrderBy',
          'IncludeZeroBalances',
          'IncludeCreditBalances',
          'EmailFromAddress',
          'EmailTemplateId'
        ],
        EmailInfo: {
          SendEmail: false,
          CustomerId: cardData.value.CustomerId,
          CustomerName: cardData.value.Name,
          Recipient: cardData.value.Email
        }
      })
    }

    const transactionDetailReportButtonClick = () => {
      ReportService.emitShowReportDialog({
        ReportId: reportInfo.report.transactionDetail.reportId,
        ParameterList: [
          { Name: 'AccountCode', Value: cardData.value.Code },
          { Name: 'BeginningDateTime', Value: '1980-01-01' }
        ],
        ParameterHideList: [
          'AccountCode',
          'BillingGroupId',
          'SelectBy',
          'TranCodeId',
          'EmailFromAddress',
          'EmailTemplateId'
        ],
        EmailInfo: {
          SendEmail: false,
          CustomerId: cardData.value.CustomerId,
          CustomerName: cardData.value.Name,
          Recipient: cardData.value.Email
        }
      })
    }

    const selectRow = row => {
      if (row.InvoiceNumber) {
        selectedTransactionId.value = row.TransactionId
        showInvoiceDialog.value = true
      }
    }

    const hideInvoiceDialog = reload => {
      showInvoiceDialog.value = false
      if (reload) {
        refresh()
      }
    }

    const navigateToCustomer = () => {
      router.push({
        name: feature.profile.routerName,
        params: { id: cardData.value.CustomerId }
      })
    }

    const billingSummaryCardUpdateEvent = () => {
      refresh()
    }

    initDialog()

    return {
      showInvoiceDialog,
      cardData,
      statusClass,
      selectedTransactionId,
      loading,
      options,
      sortBy,
      sortDesc,
      dataTable,
      headers,
      items_per_page,
      statementReportButtonClick,
      transactionDetailReportButtonClick,
      selectRow,
      hideInvoiceDialog,
      billingSummaryCardUpdateEvent,
      navigateToCustomer,
      toMoney,
      feature,
      icon,
      portalSettings
    }
  }
}
</script>

<style lang="scss" scoped></style>
