<template>
  <Login
    class="mt-3 ml-5"
    :isDialogOpen="showLoginDialog"
    :officeCode="propOfficeCode"
    @loggedIn="loggedInEvent"
  />
</template>

<script>
import { ref } from 'vue'
import { useRouter } from 'vue2-helpers/vue-router'
import Login from '@/components/User/Login'
import { isLoggedIn, propOfficeCode } from '@/store/UserSession'
export default {
  name: 'LoginPage',
  components: { Login },
  setup() {
    const router = useRouter()
    const showLoginDialog = ref(false)

    const initForm = () => {
      if (isLoggedIn.value) {
        loadHomePage()
      } else {
        showLoginDialog.value = true
      }
    }

    const loggedInEvent = event => {
      showLoginDialog.value = false
      loadHomePage()
      if (event.MustChangePassword) {
        confirm('must change password')
      }
    }

    const loadHomePage = () => {
      router.push({ name: 'home' })
    }

    initForm()
    return {
      propOfficeCode,
      showLoginDialog,
      loggedInEvent
    }
  }
}
</script>
