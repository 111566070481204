<template>
  <div>
    <v-card>
      <v-card-title>
        <DialogTitle :dialogName="$options.name">
          Recurring Payments
        </DialogTitle>
      </v-card-title>
      <v-card-text>
        <v-data-table
          dense
          :headers="recurringPaymentHeaderList"
          :items="recurringPaymentDataTable"
          class="elevation-1"
        >
          <template v-slot:item.ExpDate="{ item }">
            <span>{{ toExpDateString(item.ExpDate) }}</span>
          </template>
          <template v-slot:item.PaymentAmount="{ item }">
            <span v-if="item.PayOutstandingBalance">(Balance)</span>
            <span v-else>{{ toMoney(item.PaymentAmount) }}</span>
          </template>
          <template v-slot:item.processorData="{ item }">
            <v-tooltip bottom>
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  icon
                  v-bind="attrs"
                  v-on="on"
                  @click="showProcessorData(item)"
                >
                  <v-icon :color="feature.onlinePayments.color">
                    {{ icon.onlinePaymentProcessorInfo }}
                  </v-icon>
                </v-btn>
              </template>
              <span>Show Processor Info</span>
            </v-tooltip>
          </template>
          <template v-slot:item.oneTime="{ item }">
            <v-tooltip bottom>
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  icon
                  v-bind="attrs"
                  v-on="on"
                  @click="oneTimePaymentClick(item)"
                >
                  <v-icon :color="feature.onlinePayments.color">
                    {{ icon.onlinePaymentOneTimePost }}
                  </v-icon>
                </v-btn>
              </template>
              <span>Post one-time payment using processor info.</span>
            </v-tooltip>
          </template>
          <template v-slot:item.actions="{ item }">
            <v-icon small class="mr-2" @click="editItemClick(item.Id)">
              {{ icon.lineItemEdit }}
            </v-icon>
            <v-icon small @click="deleteItemClick(item)">
              {{ icon.lineItemDelete }}
            </v-icon>
          </template>
        </v-data-table>
      </v-card-text>
      <v-card-actions>
        <v-btn color="success" class="ml-1 mb-1" @click="closeClick">
          Close
        </v-btn>
      </v-card-actions>
    </v-card>

    <v-dialog v-model="showProcessorInfo" max-width="300">
      <v-card>
        <v-card-title>Processor Info</v-card-title>
        <v-card-text>
          <div v-html="processorInfo"></div>
        </v-card-text>
      </v-card>
    </v-dialog>

    <v-dialog
      v-model="showRecurringPaymentOneTimePostDialog"
      max-width="400"
      persistent
    >
      <RecurringPaymentOneTimePost
        :oneTimePaymentInfo="oneTimePaymentProps"
        :isDialogOpen="showRecurringPaymentOneTimePostDialog"
        @close="hideRecurringOneTimePostDialog(false)"
        @update="hideRecurringOneTimePostDialog(true)"
      />
    </v-dialog>

    <v-dialog
      v-model="showRecurringPaymentEditorDialog"
      max-width="400"
      persistent
    >
      <RecurringPaymentEditor
        :recurringPaymentId="selectedItemId"
        :isDialogOpen="showRecurringPaymentEditorDialog"
        @close="hideRecurringPaymentEditorDialog(false)"
        @update="hideRecurringPaymentEditorDialog(true)"
      />
    </v-dialog>
    <DialogUtil ref="dialogUtil" />
  </div>
</template>

<script>
import { ref, watch } from 'vue'
import { feature, icon } from '@/use/Constants'
import { host } from '@/services/HostAPI'
import { toExpDateString } from '@/use/DateFormatter'
import { toMoney } from '@/use/NumberFormatter'
import RecurringPaymentOneTimePost from '@/components/OnlinePayment/RecurringPaymentOneTimePost'
import RecurringPaymentEditor from '@/components/OnlinePayment/RecurringPaymentEditor'

export default {
  name: 'RecurringPaymentList',
  components: {
    RecurringPaymentOneTimePost,
    RecurringPaymentEditor
  },
  props: {
    customerId: {
      type: String,
      required: true
    },
    balance: {
      type: Number,
      default: 0
    },
    isDialogOpen: {
      type: Boolean,
      default: false
    }
  },
  setup(props, context) {
    const dialogUtil = ref(null)

    watch(
      () => props.isDialogOpen,
      newVal => {
        newVal && initDialog()
      }
    )

    const initDialog = () => {
      loadRecurringPaymentsDataTable()
    }

    const updateNeeded = ref(false)

    const showProcessorInfo = ref(false)
    const processorInfo = ref()

    const oneTimePaymentProps = ref()
    const showRecurringPaymentOneTimePostDialog = ref(false)

    const selectedItemId = ref()
    const showRecurringPaymentEditorDialog = ref(false)

    const recurringPaymentDataTable = ref([])

    const recurringPaymentHeaderList = [
      { value: 'PaymentLastFour', text: 'Last Four' },
      { value: 'ExpDate', text: 'Expires' },
      { value: 'RecurringPaymentFrequencyDescription', text: 'Frequency' },
      { value: 'PaymentAmount', text: 'Amount', align: 'right' },
      { value: 'processorData', text: 'Processor Info', align: 'center' },
      { value: 'oneTime', text: 'Post One Time', align: 'center' },
      { value: 'actions', text: 'Actions', sortable: false, width: 60 }
    ]

    const closeClick = () => {
      if (updateNeeded.value) {
        context.emit('update')
      } else {
        context.emit('close')
      }
    }

    const loadRecurringPaymentsDataTable = async () => {
      const rq = { Criteria: { ParentInfoId: props.customerId } }
      const rs = await host.recurringPayment.listForCustomer(rq)
      if (rs.InfoList.length === 0) {
        context.emit('update')
        context.emit('close')
      }
      recurringPaymentDataTable.value = rs.InfoList
    }

    const showProcessorData = async item => {
      await loadProcessorData(item.Id)
      showProcessorInfo.value = true
    }

    const loadProcessorData = async id => {
      const rq = { InfoId: id }
      const rs = await host.recurringPayment.getProcessorData(rq)
      processorInfo.value = rs.Info
    }

    const oneTimePaymentClick = item => {
      oneTimePaymentProps.value = {
        RecurringPaymentId: item.Id,
        PaymentAmount: props.balance
      }
      showRecurringPaymentOneTimePostDialog.value = true
    }

    const hideRecurringOneTimePostDialog = reload => {
      showRecurringPaymentOneTimePostDialog.value = false
      if (reload) {
        context.emit('update')
      }
    }

    const editItemClick = id => {
      selectedItemId.value = id
      showRecurringPaymentEditorDialog.value = true
    }

    const hideRecurringPaymentEditorDialog = reload => {
      showRecurringPaymentEditorDialog.value = false
      if (reload) {
        loadRecurringPaymentsDataTable()
        updateNeeded.value = true
      }
    }

    const deleteItemClick = item => {
      dialogUtil.value
        .confirm({
          title: 'Please confirm',
          text: 'Delete this recurring payment. Are you sure?'
        })
        .then(() => performDeleteRecurringPayment(item.Id))
        .catch(() => {
          return
        })
    }

    const performDeleteRecurringPayment = async id => {
      const rs = await deleteRecurringPayment(id)
      if (rs.IsSuccess) {
        updateNeeded.value = true
        loadRecurringPaymentsDataTable()
      } else {
        dialogUtil.value.error(rs.Message)
      }
    }

    const deleteRecurringPayment = async id => {
      const rq = { InfoId: id }
      const rs = await host.recurringPayment.delete(rq)
      return rs
    }

    initDialog()
    return {
      dialogUtil,
      feature,
      icon,
      toExpDateString,
      toMoney,
      recurringPaymentHeaderList,
      recurringPaymentDataTable,
      closeClick,
      showProcessorInfo,
      processorInfo,
      showProcessorData,
      oneTimePaymentClick,
      showRecurringPaymentOneTimePostDialog,
      hideRecurringOneTimePostDialog,
      oneTimePaymentProps,
      editItemClick,
      selectedItemId,
      showRecurringPaymentEditorDialog,
      hideRecurringPaymentEditorDialog,
      deleteItemClick
    }
  }
}
</script>

<style lang="scss" scoped></style>
