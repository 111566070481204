<template>
  <div>
    <my-video :sources="videoSources" :options="videoOptions" />
  </div>
</template>

<script>
import { ref, watch } from 'vue'
import myVideo from 'vue-video'
export default {
  name: 'TutorialPlayer',
  props: {
    url: {
      type: String,
      required: true
    },
    isDialogOpen: {
      type: Boolean,
      default: false
    }
  },
  components: { myVideo },
  setup(props) {
    watch(
      () => props.isDialogOpen,
      newVal => {
        newVal && initDialog()
      }
    )

    const videoSources = ref([
      {
        src: props.url,
        type: 'video/mp4'
      }
    ])

    const videoOptions = ref({ autoplay: props.isDialogOpen })

    const initDialog = () => {}

    initDialog()
    return { videoSources, videoOptions }
  }
}
</script>

<style lang="scss" scoped></style>
