import axios from 'axios'

// const apiURL = 'http://localhost:5400'
const apiURL = 'https://officeapi.poolcareonline.com'

const apiClient = axios.create({
  withCredentials: false
})

export default {
  headers: {
    Accept: 'application/json',
    'Content-Type': 'application/json'
  },

  apiURL: apiURL,
  authToken: null,

  setAuthToken(value) {
    this.authToken = value
  },

  setTenantId(tenantId) {
    this.headers['Tenant-Id'] = tenantId
    this.headers['Front-End'] = 'customer'
  },

  getTenantId() {
    return this.headers['Tenant-Id']
  },

  apiPost(uri, rq) {
    if (this.authToken) {
      this.headers.Authorization = `Bearer ${this.authToken}`
    }
    return apiClient.post(uri, rq, {
      baseURL: apiURL,
      headers: this.headers
    })
  },

  apiPostWithAttachment(uri, rq, att) {
    const uploadHeaders = {
      ...this.headers,
      'Content-Type': 'multipart/form-data'
    }
    if (this.authToken) {
      uploadHeaders.Authorization = `Bearer ${this.authToken}`
    }

    const formData = new FormData()

    const blob = new Blob([JSON.stringify(rq)], { type: 'application/json' })
    formData.append('Request', blob)
    formData.append('files', att)

    return apiClient.post(uri, formData, {
      baseURL: apiURL,
      headers: uploadHeaders
    })
  }
}
