<template>
  <div>
    <v-card class="mb-2 light-green lighten-4">
      <v-card-text>
        <div class="d-flex">
          <div style="width: 240px;">
            <div class="d-flex">
              <div>Stop {{ ss.SortOrder }}</div>
              <v-spacer />
              <div>{{ ss.StatusDescription }}</div>
            </div>
            <div class="d-flex">
              <div>{{ ss.AssignedTo }}</div>
              <v-spacer />
              <div>{{ ss.ServiceType }}</div>
            </div>
            <div class="d-flex">
              <div>{{ toLocaleDateString(ss.ServiceDate) }}</div>
              <v-spacer />
              <div v-if="ss.FrequencyDescription">
                <span>{{ ss.FrequencyDescription }}</span
                ><span v-if="ss.RouteCode">Route {{ ss.RouteCode }}</span>
              </div>
            </div>
          </div>
          <v-spacer />
        </div>
      </v-card-text>
    </v-card>
  </div>
</template>

<script>
import { feature } from '@/use/Constants'
import { toLocaleDateString } from '@/use/DateFormatter'
export default {
  props: {
    ss: {
      type: Object,
      required: true
    }
  },
  setup() {
    return { feature, toLocaleDateString }
  }
}
</script>

<style lang="scss" scoped></style>
