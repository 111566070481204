import Vue from 'vue'
import VueRouter from 'vue-router'
import BillingPage from '@/views/BillingPage'
import HomePage from '@/views/HomePage'
import LoginPage from '@/views/LoginPage'
import ProfilePage from '@/views/ProfilePage'
import ServicesPage from '@/views/ServicesPage'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'home',
    component: HomePage,
    props: true
  },
  {
    path: '/billing',
    name: 'billing',
    component: BillingPage,
    props: true
  },
  {
    path: '/login',
    name: 'login',
    component: LoginPage,
    props: true
  },
  {
    path: '/profile',
    name: 'profile',
    component: ProfilePage
  },
  {
    path: '/services/:siteId?/:siteDescription?',
    name: 'services',
    component: ServicesPage,
    props: true
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router
