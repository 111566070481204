<template>
  <v-app id="main">
    <NavBar />
    <v-main>
      <transition name="fade" mode="out-in">
        <router-view />
      </transition>
    </v-main>
  </v-app>
</template>

<script>
import { useRouter } from 'vue2-helpers/vue-router'
import NavBar from '@/components/layout/NavBar'
import { propOfficeCode, isLoggedIn } from '@/store/UserSession'

export default {
  name: 'App',
  components: { NavBar },
  setup() {
    const router = useRouter()
    const myUrl = new URL(location.href)

    if (!isLoggedIn.value) {
      if (myUrl.pathname === '/') {
        propOfficeCode.value = myUrl.searchParams.get('oc')
        router.push({ name: 'login' })
      }
    }

    return {}
  }
}
</script>
<style>
.fade-enter-active,
.fade-leave-active {
  transition-duration: 0.3s;
  transition-property: opacity;
  transition-timing-function: ease;
}

.fade-enter,
.fade-leave-active {
  opacity: 0;
}

.ck-content {
  height: 300px;
}

.stacked-map-icon-bottom {
  position: absolute;
  left: 0;
  text-align: center;
  width: 100%;
}

.stacked-map-icon-top {
  position: absolute;
  left: 3px;
  top: 3px;
  text-align: center;
  width: 100%;
}
</style>
