import { helpers } from '@vuelidate/validators'

const luhnCheck = value => {
  const isValid =
    !helpers.req(value) ||
    value.toUpperCase().startsWith('XXXX') ||
    computeLuhn(value) == 0
  return isValid
}

const luhnCheck$1 = {
  $validator: luhnCheck,
  $message: 'Credit card number is invalid'
}

const cvvCheck = value => {
  const isValid =
    !helpers.req(value) || value.length === 3 || value.length === 4
  return isValid
}

const cvvCheck$1 = {
  $validator: cvvCheck,
  $message: 'Must be 3 or 4 digits'
}

// private methods
const computeLuhn = value => {
  return (
    value
      .split('')
      .reverse()
      .reduce((acc, cur, idx) => {
        const d = (Number(cur) || 0) * ((idx % 2) + 1)
        const a = d > 9 ? d - 9 : d
        return Number(acc) + a
      }) % 10
  )
}

const mustBeBlank = value => {
  return !value
}

const customErrorMessage = functionToReturnMessage => {
  return {
    $validator: () => mustBeBlank(functionToReturnMessage()),
    $message: functionToReturnMessage
  }
}

export { luhnCheck$1 as luhnCheck, cvvCheck$1 as cvvCheck, customErrorMessage }
