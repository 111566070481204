<template>
  <div>
    <PageTitle
      featureName="services"
      functionName="List"
      :pageName="$options.name"
    />
    <v-card class="ma-2 indigo lighten-5" max-width="1110">
      <v-card-title>
        <div class="text-subtitle-1 blue--text">
          Search Criteria
        </div>
      </v-card-title>
      <v-card-text v-if="selectLists && siteList">
        <div class="d-flex align-content-space-around flex-wrap">
          <v-card width="350px" class="mt-2">
            <v-card-text>
              <DatePicker
                v-model="searchCriteria.ServiceDateFirst"
                label="First Service Date"
                clearable
                dense
                @change="refreshSearch"
              />
              <DatePicker
                v-model="searchCriteria.ServiceDateLast"
                label="Last Service Date"
                clearable
                dense
                @change="refreshSearch"
              />
            </v-card-text>
          </v-card>
          <v-card width="350px" class="mt-2 ml-3">
            <v-card-text>
              <v-select
                v-model="searchCriteria.Status"
                :items="selectLists.ServiceStatus"
                item-text="Text"
                item-value="Value"
                label="Status"
                clearable
                dense
                @change="refreshSearch"
              />
              <v-select
                v-model="searchCriteria.SiteId"
                :items="siteList"
                label="Site"
                item-text="Description"
                item-value="SiteId"
                dense
                @input="refreshSearch"
              />
            </v-card-text>
          </v-card>
        </div>
      </v-card-text>
    </v-card>
    <v-card class="mx-2 mt-3">
      <v-card-title>
        <span class="text-subtitle-1 blue--text">
          Search Results
        </span>
      </v-card-title>
      <v-data-table
        :items-per-page="10"
        :headers="headers"
        :items="dataTable.LineItemList"
        :options.sync="options"
        :sort-by.sync="sortBy"
        :sort-desc.sync="sortDesc"
        :server-items-length="dataTable.TotalRecords"
        :loading="loading"
        :footer-props="{ 'items-per-page-options': items_per_page }"
        class="elevation-1"
        dense
      >
        <template v-slot:item.ServiceDate="{ item }">
          <span>{{ toLocaleDateString(item.ServiceDate) }}</span>
        </template>
        <template v-slot:item.ServiceType="{ item }">
          <v-icon :color="feature.services.color">
            {{ serviceTypeIcon(item.ServiceTypeId) }}
          </v-icon>
          <span class="ml-1">{{ item.ServiceType }}</span>
        </template>
        <template v-slot:item.AssignedTo="{ item }">
          <v-icon :color="userColor(item.AssignedToUserId)">
            {{ icon.user }}
          </v-icon>
          <span class="ml-1">{{ item.AssignedTo }}</span>
        </template>
        <template v-slot:item.RouteCode="{ item }">
          <v-icon
            v-if="item.RouteCode"
            :color="serviceRouteColor(item.ServiceRouteId)"
          >
            {{ icon.routes }}
          </v-icon>
          <v-icon
            v-if="item.RecurringServiceId"
            :color="feature.recurringServices.color"
          >
            {{ icon.recurringService }}
          </v-icon>
          <span class="ml-1">{{ item.RouteCode }}{{ item.Repeats }}</span>
        </template>
        <template v-slot:item.WhenCompleted="{ item }">
          <v-icon v-if="item.WhenCompleted" color="green">
            {{ icon.completed }}
          </v-icon>
          <span class="ml-2">
            {{ toLocaleDateTimeString(item.WhenCompleted) }}
          </span>
        </template>
        <template v-slot:item.HasPhotos="{ item }">
          <v-icon v-if="item.HasPhotos" :color="feature.services.color">
            {{ icon.photo }}
          </v-icon>
        </template>
      </v-data-table>
    </v-card>
  </div>
</template>

<script>
import { ref, watch } from 'vue'
import { feature, icon } from '@/use/Constants'
import { sessionState } from '@/store/UserSession'
import { host } from '@/services/HostAPI'
import {
  toLocaleDateString,
  toLocaleDateTimeString,
  todayISOString
} from '@/use/DateFormatter'
import { selectListCache } from '@/services/SelectListCache'

export default {
  name: 'ServicesPage',
  setup() {
    const selectLists = ref()
    const siteList = ref()
    var isReady = false

    const searchCriteria = ref({
      ServiceDefId: null,
      ServiceDateFirst: null,
      ServiceDateLast: null,
      AssignedToUserId: null,
      Status: 'O',
      ServiceRouteId: null,
      SiteId: null,
      HasPhotos: false
    })

    const today = todayISOString()

    const initForm = async () => {
      searchCriteria.value.ServiceDateFirst = today.substring(0, 5) + '01-01'
      searchCriteria.value.ServiceDateLast = today
      await loadSelectLists()
      await loadSiteList()
      isReady = true
      refreshSearch()
    }

    const loadSelectLists = async () => {
      const selectListNames = [
        selectListCache.name.Users,
        selectListCache.name.ServiceStatus,
        selectListCache.name.ServiceRoutes
      ]

      selectLists.value = await selectListCache.get(selectListNames)
    }

    const loadSiteList = async () => {
      const rq = {
        Criteria: { CustomerId: sessionState.value.UserInfo.CustomerId }
      }

      const rs = await host.site.list(rq)
      siteList.value = rs.InfoList
      searchCriteria.value.SiteId = rs.InfoList[0].SiteId
    }

    const items_per_page = [5, 10, 15, 20, 50]

    const headers = [
      { value: 'ServiceDate', text: 'Date' },
      { value: 'Status', text: 'Status' },
      { value: 'SiteName', text: 'Site' },
      { value: 'ServiceType', text: 'Service Type' },
      { value: 'AssignedTo', text: 'Assigned To' },
      { value: 'RouteCode', text: 'Route / Recur' },
      { value: 'WhenCompleted', text: 'Completed' },
      { value: 'HasPhotos', text: '' }
    ]

    const options = ref({})
    const dataTable = ref([])
    const loading = ref(false)
    const sortBy = ref('Date')
    const sortDesc = ref(false)
    const syncSiteDescription = ref(null)

    const getDataFromApi = async () => {
      if (!isReady) {
        return
      }

      loading.value = true
      const { sortBy, sortDesc, page, itemsPerPage } = options.value
      const rq = {
        Pagination: {
          Page: page,
          RowsPerPage: itemsPerPage,
          SortBy: sortBy.length > 0 ? sortBy[0] : 'Date',
          IsDescending: sortDesc.length > 0 ? sortDesc[0] : false
        },
        ...searchCriteria.value
      }
      const rs = await host.scheduledService.loadDataTable(rq)
      dataTable.value = rs
      loading.value = false
    }

    const refreshSearch = () => {
      getDataFromApi()
    }

    watch(
      () => options.value,
      () => {
        getDataFromApi().catch(e => alert('error: ' + e.message))
      }
    )

    const serviceTypeIcon = value => {
      return selectListCache.attribute(
        selectListCache.name.ServiceTypes,
        value,
        'IconName'
      )
    }

    const userColor = value => {
      return selectListCache.attribute(
        selectListCache.name.Users,
        value,
        'Color'
      )
    }

    const serviceRouteColor = value => {
      return selectListCache.attribute(
        selectListCache.name.ServiceRoutes,
        value,
        'Color'
      )
    }

    initForm()

    return {
      feature,
      icon,
      searchCriteria,
      syncSiteDescription,
      selectLists,
      siteList,
      items_per_page,
      headers,
      dataTable,
      loading,
      options,
      sortBy,
      sortDesc,
      refreshSearch,
      toLocaleDateString,
      toLocaleDateTimeString,
      serviceTypeIcon,
      userColor,
      serviceRouteColor
    }
  }
}
</script>

<style lang="scss" scoped></style>
