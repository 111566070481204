<template>
  <v-menu
    v-model="menu"
    :close-on-content-click="false"
    :nudge-right="40"
    :return-value.sync="time"
    transition="scale-transition"
    offset-y
    max-width="260px"
    min-width="260px"
  >
    <template v-slot:activator="{ on }">
      <v-text-field
        :value="formattedTime"
        :label="label"
        readonly
        :dense="dense"
        v-on="on"
        :error-messages="errorMessages"
      >
        <template slot="append">
          <v-btn icon v-if="clearable && value" @click="clearButtonClick">
            <v-icon>{{ icon.clear }}</v-icon>
          </v-btn>
          <v-icon @click="menu = true">{{ icon.timePicker }}</v-icon>
        </template>
      </v-text-field>
    </template>
    <v-card width="260px">
      <div class="primary white--text pl-3">{{ label }}</div>
      <v-time-picker
        v-model="time"
        scrollable
        width="260"
        class="elevation-0"
      ></v-time-picker>
      <v-divider></v-divider>
      <v-card-actions class="justify-center">
        <v-btn text color="primary" @click="saveButtonClick">Ok</v-btn>
        <v-btn text color="primary" @click="cancelButtonClick">Cancel</v-btn>
      </v-card-actions>
    </v-card>
  </v-menu>
</template>

<script>
import { ref, watch, computed } from 'vue'
import { parseISO, format, formatISO } from 'date-fns'
import { icon } from '@/use/Constants'
export default {
  name: 'TimePicker',
  props: {
    label: {
      type: String,
      default: ''
    },
    value: {
      type: String,
      default: null
    },
    clearable: {
      type: Boolean,
      default: false
    },
    dense: {
      type: Boolean,
      default: false
    },
    errorMessages: {
      type: Array,
      default: () => []
    }
  },
  setup(props, context) {
    const menu = ref(false)
    const time = ref('')

    watch(
      () => menu.value,
      () => {
        if (props.value == null) {
          const newValue = formatISO(new Date()).substring(0, 19)
          time.value = newValue.substring(11, 16)
        } else {
          time.value = props.value
        }
      },
      { immediate: true }
    )

    const formattedTime = computed(() => {
      if (props.value == null || props.value.substring(0, 5) === '00:00') {
        return null
      }
      return format(parseISO('2020-01-01T' + props.value), 'h:mm a')
    })

    const saveButtonClick = () => {
      context.emit('input', time.value)
      context.emit('change', time.value)
      menu.value = false
    }

    const clearButtonClick = () => {
      time.value = null
      context.emit('input', time.value)
      context.emit('change', time.value)
    }

    const cancelButtonClick = () => {
      menu.value = false
    }

    return {
      menu,
      time,
      formattedTime,
      saveButtonClick,
      clearButtonClick,
      cancelButtonClick,
      icon
    }
  }
}
</script>

<style lang="scss">
.v-time-picker-title__time .v-picker__title__btn,
.v-time-picker-title__time span {
  height: 55px;
  font-size: 50px;
}
</style>
