<template>
  <div>
    <div v-if="sessionState.OfficeInfo.OfficeId" style="display: flex;">
      <img :src="logoUrl" />
      <div height="64px">
        <div
          class="office-name ml-4 mt-4"
          v-text="sessionState.OfficeInfo.OfficeName"
        />
      </div>
    </div>
  </div>
</template>

<script>
import { computed } from 'vue'
import { sessionState } from '@/store/UserSession'
import FieldService from '@/services/FieldService'

export default {
  setup() {
    const logoUrl = computed(() => {
      return `${
        FieldService.apiURL
      }/Office/Logo?tenant-id=${FieldService.getTenantId()}&officeId=${
        sessionState.value.OfficeInfo.OfficeId
      }`
    })

    return { sessionState, logoUrl }
  }
}
</script>
<style lang="scss" scoped>
.office-name {
  font-weight: 400;
  font-size: 1.5em;
}
</style>
