<template>
  <div>
    <v-card width="400">
      <v-card-title class="text-h5 blue--text">
        Customer Login
      </v-card-title>
      <v-card-text>
        <v-text-field
          v-if="!officeCode"
          v-model="formData.OfficeCode"
          label="Office Code"
          :prepend-icon="icon.office"
          :error-messages="errorMessages(vuelidate.formData.OfficeCode)"
          @blur="officeCodeBlur"
        />
        <v-text-field
          v-model="formData.LoginName"
          label="Email"
          :prepend-icon="icon.user"
          :error-messages="errorMessages(vuelidate.formData.LoginName)"
          @blur="vuelidate.formData.LoginName.$touch()"
        />
        <v-text-field
          v-model="formData.Password"
          v-if="!forgotPasswordMode"
          :type="showPassword ? 'text' : 'password'"
          label="Password"
          :prepend-icon="icon.password"
          :append-icon="showPassword ? icon.passwordShow : icon.passwordHide"
          :error-messages="errorMessages(vuelidate.formData.Password)"
          @blur="vuelidate.formData.Password.$touch()"
          @click:append="showPassword = !showPassword"
        />
      </v-card-text>
      <v-alert v-model="showError" type="error" dismissible>
        {{ loginResultMessage }}
      </v-alert>
      <v-divider></v-divider>
      <v-card-actions>
        <v-btn
          v-if="!showError && !forgotPasswordMode"
          color="info"
          @click="loginClick"
          >Login
        </v-btn>
        <v-btn
          v-if="forgotPasswordMode"
          color="info"
          @click="emailTempPasswordClick"
          >Email Temporary Password</v-btn
        >
        <v-spacer />
        <v-btn
          v-if="!forgotPasswordMode"
          @click="forgotPasswordClick"
          small
          color="error"
          >Forgot Password
        </v-btn>
      </v-card-actions>
    </v-card>
    <DialogUtil ref="dialogUtil" />
  </div>
</template>

<script>
import { ref, watch } from 'vue'
import { icon } from '@/use/Constants'
import { useVuelidate } from '@vuelidate/core'
import { required, requiredIf, email } from '@vuelidate/validators'
import { sessionState, login } from '@/store/UserSession'
import { host } from '@/services/HostAPI'
import FieldService from '@/services/FieldService'
import { cloudManager } from '@/services/CloudManagerAPI'
import { retrievePortalSettings } from '@/services/PortalSettingsCache'

export default {
  name: 'Login',
  props: {
    officeCode: {
      type: String,
      default: null
    }
  },
  setup(props, context) {
    const dialogUtil = ref(null)
    const showPassword = ref(false)
    const showError = ref(false)
    const loginResultMessage = ref()
    const selectedOfficeInfo = ref()
    const forgotPasswordMode = ref(false)

    const formData = ref({
      OfficeCode: null,
      LoginName: null,
      Password: null
    })

    const initForm = () => {
      if (props.officeCode) {
        formData.value.OfficeCode = props.officeCode
        getOfficeInfo()
      }
    }

    const officeCodeBlur = () => {
      vuelidate.value.formData.OfficeCode.$touch()
      if (formData.value.OfficeCode) {
        getOfficeInfo()
      }
    }

    watch(
      () => selectedOfficeInfo.value,
      newVal => {
        if (newVal) {
          // Banner monitors this -- will show when populated
          sessionState.value.OfficeInfo.OfficeId = newVal.OfficeId
          sessionState.value.OfficeInfo.OfficeName = newVal.OfficeName
        }
      }
    )

    const getOfficeInfo = async () => {
      const rq = {
        Code: formData.value.OfficeCode
      }

      const rs = await cloudManager.office.retrieve(rq)
      if (rs.IsSuccess) {
        FieldService.setTenantId(rs.Info.SubDomain)
        selectedOfficeInfo.value = rs.Info
      }
    }

    const rules = {
      formData: {
        OfficeCode: { required },
        LoginName: { required, email },
        Password: {
          required: requiredIf(() => {
            return !forgotPasswordMode.value
          })
        }
      }
    }

    const vuelidate = useVuelidate(rules, { formData })

    const errorMessages = item => {
      return vuelidate.value.$invalid ? item.$errors.map(e => e.$message) : []
    }

    const loginClick = async () => {
      vuelidate.value.$touch()
      if (!vuelidate.value.$invalid) {
        doLogin()
      }
    }

    const doLogin = async () => {
      const loginResult = await login({
        OfficeId: selectedOfficeInfo.value.OfficeId,
        LoginName: formData.value.LoginName,
        Password: formData.value.Password
      })

      if (loginResult.IsSuccess) {
        sessionState.value.OfficeInfo.OfficeCode =
          selectedOfficeInfo.value.OfficeCode
        sessionState.value.OfficeInfo.OfficeId =
          selectedOfficeInfo.value.OfficeId
        sessionState.value.OfficeInfo.OfficeName =
          selectedOfficeInfo.value.OfficeName
        // UI Menu options depend on portal settings, fetch now to prevent visible page building later
        await retrievePortalSettings()
        context.emit('loggedIn', loginResult)
      } else {
        loginResultMessage.value = loginResult.Message
        showError.value = true
      }
    }

    const forgotPasswordClick = () => {
      forgotPasswordMode.value = true
    }

    const emailTempPasswordClick = async () => {
      vuelidate.value.$touch()
      if (vuelidate.value.$invalid) {
        return
      }

      if (!selectedOfficeInfo.value || !selectedOfficeInfo.value.OfficeId) {
        dialogUtil.value.error('Office Code is not valid.')
        return
      }

      const rq = {
        OfficeId: selectedOfficeInfo.value.OfficeId,
        Email: formData.value.LoginName
      }

      const rs = await host.customerPortal.forgotPassword(rq)
      if (rs.IsSuccess) {
        dialogUtil.value.inform(rs.Message)
        formData.value.Password = null
        forgotPasswordMode.value = false
      } else {
        dialogUtil.value.error(rs.Message)
      }
    }

    initForm()

    return {
      dialogUtil,
      formData,
      showPassword,
      loginClick,
      icon,
      forgotPasswordMode,
      forgotPasswordClick,
      emailTempPasswordClick,
      showError,
      loginResultMessage,
      errorMessages,
      vuelidate,
      officeCodeBlur
    }
  }
}
</script>
