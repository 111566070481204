<template>
  <div>
    <v-card class="my-2">
      <v-card-text>
        <div class="d-flex">
          <div style="width: 100%;">
            <div v-if="cardData.RecurringPaymentList.length === 0">
              No recurring payment info on file
            </div>
            <div
              v-for="rp in cardData.RecurringPaymentList"
              :key="rp.RecurringPaymentId"
            >
              <div>Card {{ rp.PaymentLastFour }} Exp {{ rp.ExpDate }}</div>
              <div>
                <span>{{ rp.FrequencyDescription }}</span>
                <span>: </span>
                <span>
                  {{
                    rp.PayOutstandingBalance
                      ? 'Balance'
                      : toCurrency(rp.PaymentAmount)
                  }}
                </span>
              </div>
            </div>
          </div>
          <v-spacer />
          <div v-if="!readonly">
            <v-btn small @click="onlinePaymentClick">
              <v-icon :color="feature.onlinePayments.color">
                {{ icon.onlinePayment }}
              </v-icon>
              Payment
            </v-btn>

            <v-btn
              class="mt-3"
              v-if="cardData.RecurringPaymentList.length > 0"
              small
              @click="recurringPaymentListClick"
            >
              <v-icon :color="feature.onlinePayments.color">
                {{ feature.onlinePayments.iconRecurringPayment }}
              </v-icon>
              Recurring
            </v-btn>
          </div>
        </div>
      </v-card-text>
    </v-card>

    <v-dialog v-model="showOnlinePaymentDialog" max-width="400">
      <OnlinePayment
        :customerId="cardData.CustomerId"
        :customerName="cardData.Name"
        :address="cardData.Address.Street"
        :postalCode="cardData.Address.PostCode"
        :amount="cardData.AgingSummary.Balance"
        :isDialogOpen="showOnlinePaymentDialog"
        @close="hideOnlinePaymentDialog(false)"
        @update="hideOnlinePaymentDialog(true)"
      />
    </v-dialog>

    <v-dialog
      v-model="showRecurringPaymentListDialog"
      :customerId="cardData.CustomerId"
      max-width="800"
    >
      <RecurringPaymentList
        :customerId="cardData.CustomerId"
        :balance="cardData.AgingSummary.Balance"
        :isDialogOpen="showRecurringPaymentListDialog"
        @close="customerOnlinePaymentListClose"
        @update="customerOnlinePaymentListUpdate"
      />
    </v-dialog>
  </div>
</template>

<script>
import { ref } from 'vue'
import { useRouter } from 'vue2-helpers/vue-router'
import { feature, icon } from '@/use/Constants'
import OnlinePayment from '@/components/OnlinePayment/OnlinePayment'
import RecurringPaymentList from '@/components/OnlinePayment/RecurringPaymentList'
import { toCurrency } from '@/use/NumberFormatter'
export default {
  name: 'OnlinePaymentCard',
  components: { OnlinePayment, RecurringPaymentList },
  props: {
    cardData: {
      type: Object,
      required: true
    },
    readonly: {
      type: Boolean,
      default: false
    }
  },
  setup(props, context) {
    const router = useRouter()

    const showOnlinePaymentDialog = ref(false)
    const showRecurringPaymentListDialog = ref(false)

    const onlinePaymentClick = () => {
      showOnlinePaymentDialog.value = true
    }

    const onlinePaymentDetailsClick = () => {
      router.push({
        name: feature.onlinePayments.routerName,
        params: { id: props.cardData.CustomerId }
      })
    }

    const hideOnlinePaymentDialog = reload => {
      showOnlinePaymentDialog.value = false
      if (reload) {
        context.emit('update')
      }
    }

    const customerOnlinePaymentListClose = () => {
      showRecurringPaymentListDialog.value = false
    }

    const customerOnlinePaymentListUpdate = () => {
      showRecurringPaymentListDialog.value = false
      context.emit('update')
    }

    const recurringPaymentListClick = () => {
      showRecurringPaymentListDialog.value = true
    }

    return {
      feature,
      icon,
      showOnlinePaymentDialog,
      hideOnlinePaymentDialog,
      onlinePaymentClick,
      onlinePaymentDetailsClick,
      showRecurringPaymentListDialog,
      customerOnlinePaymentListClose,
      customerOnlinePaymentListUpdate,
      recurringPaymentListClick,
      toCurrency
    }
  }
}
</script>

<style lang="scss" scoped></style>
