<template>
  <div>
    <v-app-bar flat app>
      <v-app-bar-nav-icon
        v-if="isLoggedIn"
        class="grey--text"
        @click="navDrawerToggle"
      />
      <v-toolbar-title class="grey--text">
        <Banner class="ml-5 mb-0" />
      </v-toolbar-title>

      <v-spacer />
      <FeedbackButton v-if="isLoggedIn" />
      <LoginButton />
    </v-app-bar>

    <NavDrawer v-if="isLoggedIn" :state="navDrawerState" />
    <ReportHandler />
  </div>
</template>

<script>
import { ref } from 'vue'
import { isLoggedIn } from '@/store/UserSession'
import NavDrawer from '@/components/layout/NavDrawer'
import Banner from '@/components/layout/Banner'
import LoginButton from '@/components/layout/LoginButton'
import FeedbackButton from '@/components/layout/FeedbackButton'
import ReportHandler from '@/components/layout/ReportHandler'

export default {
  name: 'NavBar',
  components: {
    Banner,
    FeedbackButton,
    LoginButton,
    NavDrawer,
    ReportHandler
  },
  setup() {
    const navDrawerState = ref(1)

    const navDrawerToggle = () => {
      navDrawerState.value++
    }

    return { isLoggedIn, navDrawerState, navDrawerToggle }
  }
}
</script>
