<template>
  <div v-if="formData && selectLists">
    <v-card width="9000">
      <v-card-title>
        <DialogTitle :dialogName="$options.name">
          {{ isEditMode ? 'Edit Invoice' : 'New Invoice' }}
        </DialogTitle>
      </v-card-title>
      <v-card-text>
        <div class="d-flex flex-wrap">
          <v-card width="300">
            <v-card-text>
              <v-text-field
                readonly
                v-model="formData.InvoiceNumber"
                label="Invoice Number"
                dense
              />
              <DatePicker
                readonly
                v-model="formData.BusinessDate"
                label="Invoice Date"
                dense
              />
            </v-card-text>
          </v-card>
          <v-card width="300" class="ml-4">
            <v-card-text>
              <v-textarea
                v-model="formData.Description"
                readonly
                label="Description"
                dense
              />
            </v-card-text>
          </v-card>
          <v-card width="300" class="ml-4 indigo lighten-5">
            <v-card-text>
              <div>{{ formData.CustomerName }}</div>
              <div>{{ formData.Address.Street }}</div>
              <div>
                <span>{{ formData.Address.City }}, </span>
                <span>{{ formData.Address.State }} </span>
                <span>{{ formData.Address.PostCode }}</span>
              </div>
              <div class="mt-3 body-1 font-weight-medium purple--text">
                Invoice Total: {{ toMoney(invoiceTotal) }}
              </div>
            </v-card-text>
          </v-card>
        </div>
        <div>
          <v-card class="mt-2">
            <v-card-text>
              <v-data-table
                dense
                :items-per-page="5"
                :footer-props="{
                  'items-per-page-options': items_per_page,
                  'show-first-last-page': true
                }"
                :headers="headers"
                :items="formData.LineItemList"
                class="elevation-1"
              >
                <template v-slot:item.TranCodeId="{ item }">
                  <span>
                    {{
                      selectLists.TranCodes.find(
                        sl => sl.Value == item.TranCodeId
                      ).Text
                    }}
                  </span>
                </template>
                <template v-slot:item.Rate="{ item }">
                  <span>{{ toMoney(item.Rate) }}</span>
                </template>
                <template v-slot:item.Quantity="{ item }">
                  <span>{{ item.Quantity }}</span>
                </template>
                <template v-slot:item.Amount="{ item }">
                  <span>{{ toMoney(item.Amount) }}</span>
                </template>
              </v-data-table>
            </v-card-text>
          </v-card>
        </div>
      </v-card-text>
      <v-card-actions>
        <v-btn color="error" class="ml-3" @click="cancelClick">Back</v-btn>
        <v-spacer />
        <v-btn small color="primary" class="mr-2" @click="printClick">
          <v-icon class="mr-2">{{ icon.report }}</v-icon>
          Print / Email
        </v-btn>
      </v-card-actions>
    </v-card>
  </div>
</template>

<script>
import { ref, computed, watch } from 'vue'
import reportInfo from '@/constants/reports'
import { host } from '@/services/HostAPI'
import ReportService from '@/services/ReportService'
import { selectListCache } from '@/services/SelectListCache'
import { icon } from '@/use/Constants'
import { toMoney } from '@/use/NumberFormatter'

export default {
  name: 'Invoice',
  props: {
    isDialogOpen: {
      type: Boolean,
      default: false
    },
    customerId: {
      type: String
    },
    invoiceId: {
      type: String,
      default: null
    },
    scheduledServiceId: {
      type: String,
      default: null
    }
  },
  components: {},
  setup(props, context) {
    const initDialog = () => {
      loadSelectLists()
      isEditMode.value = props.invoiceId != null
      if (isEditMode.value) {
        retrieveInvoice(props.invoiceId)
      } else {
        newInvoiceTemplate()
      }
    }

    watch(
      () => props.isDialogOpen,
      newVal => {
        newVal && initDialog()
      }
    )

    const selectLists = ref([])
    const formData = ref()
    const isEditMode = ref(false)
    const isEditInvoiceLine = ref(false)
    const selectedItemInfo = ref()
    const updated = ref(false)

    const loadSelectLists = async () => {
      const selectListNames = [selectListCache.name.TranCodes]

      selectLists.value = await selectListCache.get(selectListNames)
    }

    const headers = [
      { value: 'SortOrder', text: 'Line', width: 40, align: 'right' },
      { value: 'TranCodeId', text: 'Code' },
      { value: 'Reference', text: 'Reference' },
      { value: 'Rate', text: 'Rate', width: 100, align: 'right' },
      { value: 'Quantity', text: 'Quantity', width: 100, align: 'right' },
      { value: 'Amount', text: 'Amount', width: 100, align: 'right' }
    ]

    const items_per_page = [5, 10, 15, 20, 50]

    const retrieveInvoice = async id => {
      const rq = { InfoId: id }
      const rs = await host.invoice.retrieve(rq)
      formData.value = rs.Info
    }

    const newInvoiceTemplate = async () => {
      const rq = {
        Defaults: {
          CustomerId: props.customerId,
          ScheduledServiceId: props.scheduledServiceId
        }
      }
      const rs = await host.invoice.newTemplate(rq)
      formData.value = rs.Info
    }

    const invoiceTotal = computed(() => {
      return formData.value.LineItemList.reduce(function(total, currentValue) {
        return total + currentValue.Amount
      }, 0)
    })

    const maxSortOrder = computed(() => {
      return formData.value.LineItemList.length === 0
        ? 0
        : parseInt(
            formData.value.LineItemList.reduce(function(prev, current) {
              return current.SortOrder < 999 &&
                current.SortOrder > prev.SortOrder
                ? current
                : prev
            }).SortOrder
          )
    })

    const cancelClick = () => {
      if (updated.value) {
        context.emit('update')
      } else {
        context.emit('close')
      }
    }

    const printClick = async () => {
      ReportService.showReportDialog({
        ReportId: reportInfo.report.invoice.reportId,
        ParameterList: [
          { Name: 'TransactionId', Value: formData.value.InvoiceId }
        ],
        ParameterHideList: ['TransactionId'],
        EmailInfo: {
          SendEmail: false,
          CustomerId: formData.value.CustomerId,
          CustomerName: formData.value.CustomerName,
          Recipient: formData.value.Email
        }
      })
    }

    initDialog()

    return {
      selectLists,
      headers,
      items_per_page,
      reportInfo,
      formData,
      invoiceTotal,
      maxSortOrder,
      isEditMode,
      isEditInvoiceLine,
      selectedItemInfo,
      cancelClick,
      printClick,
      toMoney,
      icon
    }
  }
}
</script>

<style lang="scss" scoped></style>
