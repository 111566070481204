<template>
  <div>
    <v-card class="mt-2">
      <v-card-title>
        <span class="text-subtitle-1">Services</span>
        <v-spacer />
        <span>
          <div class="d-flex">
            <v-tooltip top>
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  icon
                  small
                  v-bind="attrs"
                  v-on="on"
                  @click="navigateToServiceSearch"
                  class="mr-1"
                >
                  <v-icon :color="feature.services.color">
                    {{ feature.services.icon }}
                  </v-icon>
                </v-btn>
              </template>
              <span>
                See Service list
              </span>
            </v-tooltip>
          </div>
        </span>
      </v-card-title>
      <v-card-text>
        <ServiceSummaryCard
          v-for="ss in cardData.ServiceList"
          :key="ss.ScheduledServiceId"
          :ss="ss"
        />
      </v-card-text>
    </v-card>
  </div>
</template>

<script>
import { ref } from 'vue'
import { useRouter } from 'vue2-helpers/vue-router'
import { feature } from '@/use/Constants'
import ServiceSummaryCard from '@/components/ScheduledService/ServiceSummaryCard'
export default {
  props: {
    cardData: { type: Object, required: true }
  },
  components: {
    ServiceSummaryCard
  },
  setup(props) {
    const router = useRouter()

    const selectedScheduledServiceId = ref()
    const selectedRecurringServiceId = ref()

    const navigateToServiceSearch = () => {
      router.push({
        name: feature.services.routerName,
        params: {
          siteId: props.cardData.SiteId,
          siteDescription: props.cardData.Description
        }
      })
    }

    return {
      feature,
      navigateToServiceSearch,
      selectedScheduledServiceId,
      selectedRecurringServiceId
    }
  }
}
</script>

<style lang="scss" scoped></style>
