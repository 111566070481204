<template>
  <div>
    <v-menu :nudge-width="250" translate="slide-x-reverse-transition" offset-x>
      <template #activator="{ on: onMenu }">
        <v-tooltip bottom>
          <template #activator="{ on: onTooltip }">
            <v-btn
              small
              rounded
              color="secondary"
              v-on="{ ...onMenu, ...onTooltip }"
            >
              {{ userName.substring(0, 1) }}
            </v-btn>
          </template>
          <span>{{ userName }}</span>
        </v-tooltip>
      </template>
      <v-card height="240" width="300" shaped class="mx-auto indigo lighten-5">
        <v-card-text>
          <v-card>
            <v-card-title>{{ userName }}</v-card-title>
            <v-card-text>
              <v-list dense>
                <v-list-item
                  v-for="(dialogMenuItem, index) in menuItemList"
                  :key="index"
                  @click="menuItemClick(dialogMenuItem)"
                  dense
                >
                  <v-list-item-icon>
                    <v-icon color="primary">{{ dialogMenuItem.icon }}</v-icon>
                  </v-list-item-icon>
                  <v-list-item-content>
                    <v-list-item-title>
                      {{ dialogMenuItem.title }}
                    </v-list-item-title>
                  </v-list-item-content>
                </v-list-item>
                <v-list-item @click="logoutClick" dense>
                  <v-list-item-icon>
                    <v-icon color="primary">{{ icon.signout }}</v-icon>
                  </v-list-item-icon>
                  <v-list-item-content>
                    <v-list-item-title>
                      Sign Out
                    </v-list-item-title>
                  </v-list-item-content>
                </v-list-item>
              </v-list>
            </v-card-text>
          </v-card>
        </v-card-text>
      </v-card>
    </v-menu>

    <v-dialog
      v-model="menuItemList.changePassword.showDialog"
      :width="menuItemList.changePassword.dialogWidth"
    >
      <ChangePasswordEditor
        :isDialogOpen="menuItemList.changePassword.showDialog"
        @close="menuItemList.changePassword.closeEvent"
      />
    </v-dialog>
  </div>
</template>

<script>
import { ref, computed, watch } from 'vue'
import { useRouter } from 'vue2-helpers/vue-router'
import { icon } from '@/use/Constants'
import { SetupMenuItem } from '@/components/layout/SetupMenuItem'
import ChangePasswordEditor from '@/components/User/ChangePasswordEditor'
import { sessionState, mustChangePassword, logout } from '@/store/UserSession'
export default {
  name: 'LoginButton',
  components: {
    ChangePasswordEditor
  },
  setup() {
    const router = useRouter()

    const menuItemList = ref({
      changePassword: new SetupMenuItem('Change Password', {
        icon: icon.password,
        dialogWidth: 500
      })
    })

    const menuItemClick = menuItem => {
      menuItem.showDialog = true
    }

    watch(
      () => mustChangePassword,
      newVal => {
        menuItemList.value.changePassword.showDialog = newVal
      },
      { immediate: true }
    )

    const logoutClick = () => {
      const oc = sessionState.value.OfficeInfo.OfficeCode
      logout()
      const searchParams = {}
      if (oc) {
        searchParams['oc'] = oc
      }
      router.push({ name: 'login', params: searchParams })
    }

    const userName = computed(() => {
      return sessionState.value.UserInfo.UserName
    })

    return {
      userName,
      menuItemList,
      menuItemClick,
      logoutClick,
      icon
    }
  }
}
</script>

<style lang="scss" scoped></style>
