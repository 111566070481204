import axios from 'axios'

export default {
  headers: {
    Accept: 'application/json',
    'Content-Type': 'application/json',
    Authorization: 'ApiKey BrU18vxzY1s30YPdVQl88IPmlQyf9Rx6jsOjIHUb'
  },
  baseURL: 'https://cloudmanagerapi.poolcareonline.com',
  // baseURL: 'http://localhost:5000',

  doPost(uri, rq) {
    return axios.post('/' + uri, rq, {
      baseURL: this.baseURL,
      headers: this.headers
    })
  }
}
