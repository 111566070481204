import FieldService from '@/services/FieldService'

const postToApi = async (controllerName, operationName, rq) => {
  const rs = await FieldService.apiPost(
    `${controllerName}/${operationName}`,
    rq
  )
  return rs.data
}

const postToApiWithAttachment = async (
  controllerName,
  operationName,
  rq,
  att
) => {
  const rs = await FieldService.apiPostWithAttachment(
    `${controllerName}/${operationName}`,
    rq,
    att
  )
  return rs.data
}

class ControllerBase {
  constructor(name) {
    this.name = name
  }

  create = async rq => {
    return postToApi(this.name, 'Create', rq)
  }

  retrieve = async rq => {
    return postToApi(this.name, 'Retrieve', rq)
  }

  update = async rq => {
    return postToApi(this.name, 'Update', rq)
  }

  delete = async rq => {
    return postToApi(this.name, 'Delete', rq)
  }

  list = async rq => {
    return postToApi(this.name, 'List', rq)
  }

  newTemplate = async rq => {
    return postToApi(this.name, 'NewTemplate', rq)
  }
}

class ActivityLogController extends ControllerBase {
  constructor() {
    super('ActivityLog')
  }
}

class AutoTaskRuleController extends ControllerBase {
  constructor() {
    super('AutoTaskRule')
  }
}

class BillingController {
  constructor() {
    this.name = 'Billing'
  }

  deleteTransactionBatch = async rq => {
    return postToApi(this.name, 'DeleteTransactionBatch', rq)
  }

  getAgingSummary = async rq => {
    return postToApi(this.name, 'GetAgingSummary', rq)
  }

  getLateFeeDefaults = async rq => {
    return postToApi(this.name, 'GetLateFeeDefaults', rq)
  }

  keywordSearch = async rq => {
    return postToApi(this.name, 'KeywordSearch', rq)
  }

  loadDetailsDataTable = async rq => {
    return postToApi(this.name, 'LoadDetailsDataTable', rq)
  }

  loadTransactionBatchesDataTable = async rq => {
    return postToApi(this.name, 'LoadTransactionBatchesDataTable', rq)
  }

  postBillingBatch = async rq => {
    return postToApi(this.name, 'PostBillingBatch', rq)
  }

  postLateFees = async rq => {
    return postToApi(this.name, 'PostLateFees', rq)
  }
}

class BillingGroupController extends ControllerBase {
  constructor() {
    super('BillingGroup')
  }
}

class CalendarController {
  constructor() {
    this.name = 'Calendar'
  }

  list = async rq => {
    return postToApi(this.name, 'List', rq)
  }
}

class CommissionController extends ControllerBase {
  constructor() {
    super('Commission')
  }

  loadDataTable = async rq => {
    return postToApi(this.name, 'LoadDataTable', rq)
  }
}

class CommissionTypeController extends ControllerBase {
  constructor() {
    super('CommissionType')
  }
}

class ConfigurationController {
  constructor() {
    this.name = 'Configuration'
  }

  retrieveGoogleMapKey = async rq => {
    return postToApi(this.name, 'RetrieveGoogleMapKey', rq)
  }
}

class CustomerAlertTypeController extends ControllerBase {
  constructor() {
    super('CustomerAlertType')
  }
}

class CustomerController extends ControllerBase {
  constructor() {
    super('Customer')
  }

  card = async rq => {
    return postToApi(this.name, 'Card', rq)
  }

  customViewList = async rq => {
    return postToApi(this.name, 'CustomViewList', rq)
  }

  keywordSearch = async rq => {
    return postToApi(this.name, 'KeywordSearch', rq)
  }

  loadCustomViewDataTable = async rq => {
    return postToApi(this.name, 'LoadCustomViewDataTable', rq)
  }

  resetPassword = async rq => {
    return postToApi(this.name, 'ResetPassword', rq)
  }
}

class CustomerCustomInfoController extends ControllerBase {
  constructor() {
    super('CustomerCustomInfo')
  }
}

class CustomerPortalController {
  constructor() {
    this.name = 'CustomerPortal'
  }

  retrieveHomePageInfo = async rq => {
    return postToApi(this.name, 'RetrieveHomePageInfo', rq)
  }

  changePassword = async rq => {
    return postToApi(this.name, 'ChangePassword', rq)
  }

  sendFeedback = async rq => {
    return postToApi(this.name, 'SendFeedback', rq)
  }

  forgotPassword = async rq => {
    return postToApi(this.name, 'ForgotPassword', rq)
  }
}

class CustomerPortalSettingsController {
  constructor() {
    this.name = 'CustomerPortalSettings'
  }

  retrieve = async rq => {
    return postToApi(this.name, 'Retrieve', rq)
  }

  update = async rq => {
    return postToApi(this.name, 'Update', rq)
  }
}

class CustomerTypeController extends ControllerBase {
  constructor() {
    super('CustomerType')
  }
}

class CustomViewController extends ControllerBase {
  constructor() {
    super('CustomView')
  }
}

class DocumentController extends ControllerBase {
  constructor() {
    super('Document')
  }

  create = async (rq, attachment) => {
    return postToApiWithAttachment(
      this.name,
      'CreateWithAttachment',
      rq,
      attachment
    )
  }
}

class EmailController {
  constructor() {
    this.name = 'Email'
  }

  sendCustomerNotifications = async rq => {
    return postToApi(this.name, 'SendCustomerNotifications', rq)
  }

  sendScheduledServiceNotifications = async rq => {
    return postToApi(this.name, 'SendScheduledServiceNotifications', rq)
  }
}

class EmailTemplateController extends ControllerBase {
  constructor() {
    super('EmailTemplate')
  }
}

class EstimateController extends ControllerBase {
  constructor() {
    super('Estimate')
  }

  invoice = async rq => {
    return postToApi(this.name, 'Invoice', rq)
  }

  loadDataTable = async rq => {
    return postToApi(this.name, 'LoadDataTable', rq)
  }
}

class InvoiceController extends ControllerBase {
  constructor() {
    super('Invoice')
  }
}

class MeasureController extends ControllerBase {
  constructor() {
    super('Measure')
  }
}

class MeasureTypeController extends ControllerBase {
  constructor() {
    super('MeasureType')
  }
}

class OfficeController extends ControllerBase {
  constructor() {
    super('Office')
  }

  feedback = async rq => {
    return postToApi(this.name, 'Feedback', rq)
  }

  retrieveSettings = async rq => {
    return postToApi(this.name, 'RetrieveSettings', rq)
  }

  updateSettings = async rq => {
    return postToApi(this.name, 'UpdateSettings', rq)
  }
}

class OnlinePaymentAuthController {
  constructor() {
    this.name = 'OnlinePaymentAuth'
  }

  create = async rq => {
    return postToApi(this.name, 'Create', rq)
  }

  complete = async rq => {
    return postToApi(this.name, 'Complete', rq)
  }

  list = async rq => {
    return postToApi(this.name, 'List', rq)
  }
}

class OnlinePaymentController {
  constructor() {
    this.name = 'OnlinePayment'
  }

  post = async rq => {
    return postToApi(this.name, 'Post', rq)
  }

  refund = async rq => {
    return postToApi(this.name, 'Refund', rq)
  }

  void = async rq => {
    return postToApi(this.name, 'Void', rq)
  }

  retrieveTransactionInfo = async rq => {
    return postToApi(this.name, 'RetrieveTransactionInfo', rq)
  }
}

class OnlinePaymentSettingsController {
  constructor() {
    this.name = 'OnlinePaymentSettings'
  }

  retrieve = async rq => {
    return postToApi(this.name, 'Retrieve', rq)
  }

  update = async rq => {
    return postToApi(this.name, 'Update', rq)
  }
}

class PhotoController {
  constructor() {
    this.name = 'Photo'
  }

  createWithAttachment = async (rq, attachment) => {
    return postToApiWithAttachment(this.name, 'Upload', rq, attachment)
  }

  retrieve = async rq => {
    return postToApi(this.name, 'Retrieve', rq)
  }

  update = async rq => {
    return postToApi(this.name, 'Update', rq)
  }

  delete = async rq => {
    return postToApi(this.name, 'Delete', rq)
  }

  list = async rq => {
    return postToApi(this.name, 'List', rq)
  }
}

class QuickBooksOnlineController {
  constructor() {
    this.name = 'QuickBooksOnline'
  }

  queueNewSession = async rq => {
    return postToApi(this.name, 'QueueNewSession', rq)
  }

  getAuthRequestUrl = async rq => {
    return postToApi(this.name, 'GetAuthRequestUrl', rq)
  }

  retrieveSettings = async rq => {
    return postToApi(this.name, 'RetrieveSettings', rq)
  }
}

class QuickBooksSettingsController {
  constructor() {
    this.name = 'QuickBooksSettings'
  }

  retrieve = async rq => {
    return postToApi(this.name, 'Retrieve', rq)
  }

  update = async rq => {
    return postToApi(this.name, 'Update', rq)
  }
}

class RateController extends ControllerBase {
  constructor() {
    super('Rate')
  }

  customerList = async rq => {
    return postToApi(this.name, 'CustomerList', rq)
  }
}

class RecurringPaymentController extends ControllerBase {
  constructor() {
    super('RecurringPayment')
  }

  saveNew = async rq => {
    return postToApi(this.name, 'SaveNew', rq)
  }

  modify = async rq => {
    return postToApi(this.name, 'Modify', rq)
  }

  getProcessorData = async rq => {
    return postToApi(this.name, 'GetProcessorData', rq)
  }

  listForCustomer = async rq => {
    return postToApi(this.name, 'ListForCustomer', rq)
  }

  oneTimePost = async rq => {
    return postToApi(this.name, 'OneTimePost', rq)
  }
}

class RecurringPaymentFrequencyController extends ControllerBase {
  constructor() {
    super('RecurringPaymentFrequency')
  }
}

class RecurringServiceController extends ControllerBase {
  constructor() {
    super('RecurringService')
  }

  listForUserByPattern = async rq => {
    return postToApi(this.name, 'ListForUserByPattern', rq)
  }

  reassign = async rq => {
    return postToApi(this.name, 'Reassign', rq)
  }
}

class ReferralTypeController extends ControllerBase {
  constructor() {
    super('ReferralType')
  }
}

class ReportController {
  constructor() {
    this.name = 'Report'
  }

  retrieveCriteria = async rq => {
    return postToApi(this.name, 'RetrieveCriteria', rq)
  }

  getHistory = async rq => {
    return postToApi(this.name, 'GetHistory', rq)
  }

  menuItemList = async rq => {
    return postToApi(this.name, 'MenuItemList', rq)
  }

  poll = async rq => {
    return postToApi(this.name, 'Poll', rq)
  }

  queue = async rq => {
    return postToApi(this.name, 'Queue', rq)
  }
}

class SalesTaxController extends ControllerBase {
  constructor() {
    super('SalesTax')
  }
}

class SalesTaxGroupController extends ControllerBase {
  constructor() {
    super('SalesTaxGroup')
  }
}

class ScheduledServiceController extends ControllerBase {
  constructor() {
    super('ScheduledService')
  }

  extendedCreate = async rq => {
    return postToApi(this.name, 'ExtendedCreate', rq)
  }

  extendedUpdate = async rq => {
    return postToApi(this.name, 'ExtendedUpdate', rq)
  }

  cardList = async rq => {
    return postToApi(this.name, 'CardList', rq)
  }

  getChecklist = async rq => {
    return postToApi(this.name, 'GetChecklist', rq)
  }

  getNearby = async rq => {
    return postToApi(this.name, 'GetNearby', rq)
  }

  loadDataTable = async rq => {
    return postToApi(this.name, 'LoadDataTable', rq)
  }

  moveSameDate = async rq => {
    return postToApi(this.name, 'MoveSameDate', rq)
  }

  moveToDate = async rq => {
    return postToApi(this.name, 'MoveToDate', rq)
  }

  optimize = async rq => {
    return postToApi(this.name, 'Optimize', rq)
  }
}

class ScheduledServiceSeasonController extends ControllerBase {
  constructor() {
    super('ScheduledServiceSeason')
  }
}

class ScheduledTaskController extends ControllerBase {
  constructor() {
    super('ScheduledTask')
  }

  getSummary = async rq => {
    return postToApi(this.name, 'GetSummary', rq)
  }

  loadDataTable = async rq => {
    return postToApi(this.name, 'LoadDataTable', rq)
  }
}

class SelectListController {
  constructor() {
    this.name = 'SelectList'
  }

  get = async rq => {
    return postToApi(this.name, 'Get', rq)
  }

  syncWithTranCodeGroups = async rq => {
    return postToApi(this.name, 'SyncWithTranCodeGroups', rq)
  }
}

class ServiceableController extends ControllerBase {
  constructor() {
    super('Serviceable')
  }
}

class ServiceableTypeController extends ControllerBase {
  constructor() {
    super('ServiceableType')
  }
}

class ServiceCommissionRuleController extends ControllerBase {
  constructor() {
    super('ServiceCommissionRule')
  }
}

class ServiceRouteAssignmentController extends ControllerBase {
  constructor() {
    super('ServiceRouteAssignment')
  }
}

class ServiceRouteController extends ControllerBase {
  constructor() {
    super('ServiceRoute')
  }

  retrieveWithSiteList = async rq => {
    return postToApi(this.name, 'RetrieveWithSiteList', rq)
  }

  optimize = async rq => {
    return postToApi(this.name, 'Optimize', rq)
  }

  organizeList = async rq => {
    return postToApi(this.name, 'OrganizeList', rq)
  }

  updateSitesForRoutes = async rq => {
    return postToApi(this.name, 'UpdateSitesForRoutes', rq)
  }
}

class ServiceRouteSiteController extends ControllerBase {
  constructor() {
    super('ServiceRouteSite')
  }
}

class ServiceTypeController extends ControllerBase {
  constructor() {
    super('ServiceType')
  }
}

class SessionController {
  constructor() {
    this.name = 'Session'
  }

  customerLogin = async rq => {
    return postToApi(this.name, 'CustomerLogin', rq)
  }

  login = async rq => {
    return postToApi(this.name, 'Login', rq)
  }
}

class SiteController extends ControllerBase {
  constructor() {
    super('Site')
  }

  card = async rq => {
    return postToApi(this.name, 'Card', rq)
  }

  keywordSearch = async rq => {
    return postToApi(this.name, 'KeywordSearch', rq)
  }
}

class SiteCustomInfoController extends ControllerBase {
  constructor() {
    super('SiteCustomInfo')
  }
}

class SubscriptionController {
  constructor() {
    this.name = 'Subscription'
  }

  retrieve = async rq => {
    return postToApi(this.name, 'Retrieve', rq)
  }

  update = async rq => {
    return postToApi(this.name, 'Update', rq)
  }

  subscribe = async rq => {
    return postToApi(this.name, 'Subscribe', rq)
  }

  unsubscribe = async rq => {
    return postToApi(this.name, 'Unsubscribe', rq)
  }
}

class SyncController {
  constructor() {
    this.name = 'Sync'
  }

  list = async rq => {
    return postToApi(this.name, 'List', rq)
  }
}

class SyncSessionController extends ControllerBase {
  constructor() {
    super('SyncSession')
  }

  stepList = async rq => {
    return postToApi(this.name, 'StepList', rq)
  }
}

class SyncWithController extends ControllerBase {
  constructor() {
    super('SyncWith')
  }
}

class TaskTypeController extends ControllerBase {
  constructor() {
    super('TaskType')
  }
}

class TerminationTypeController extends ControllerBase {
  constructor() {
    super('TerminationType')
  }
}

class TranCodeController extends ControllerBase {
  constructor() {
    super('TranCode')
  }
}

class TranCodeGroupController extends ControllerBase {
  constructor() {
    super('TranCodeGroup')
  }
}

class TransactionBatchController extends ControllerBase {
  constructor() {
    super('TransactionBatch')
  }

  retrieveWithItems = async rq => {
    return postToApi(this.name, 'RetrieveWithItems', rq)
  }

  changeStatus = async rq => {
    return postToApi(this.name, 'ChangeStatus', rq)
  }
}

class TransactionController extends ControllerBase {
  constructor() {
    super('Transaction')
  }

  saveOrUpdateInBatch = async rq => {
    return postToApi(this.name, 'SaveOrUpdateInBatch', rq)
  }
}

class TreatmentController extends ControllerBase {
  constructor() {
    super('Treatment')
  }
}

class TreatmentTypeController extends ControllerBase {
  constructor() {
    super('TreatmentType')
  }
}

class UserController extends ControllerBase {
  constructor() {
    super('User')
  }

  changePassword = async rq => {
    return postToApi(this.name, 'ChangePassword', rq)
  }

  recentlyAccessedItems = async rq => {
    return postToApi(this.name, 'RecentlyAccessedItems', rq)
  }

  resetPassword = async rq => {
    return postToApi(this.name, 'ResetPassword', rq)
  }
}

class UserGroupController extends ControllerBase {
  constructor() {
    super('UserGroup')
  }
}

class VideoController {
  constructor() {
    this.name = 'Video'
  }

  upload = async (rq, attachment) => {
    return postToApiWithAttachment(this.name, 'Upload', rq, attachment)
  }

  getUrl = (containerName, fileName) => {
    return `${
      FieldService.apiURL
    }/Video/Play?containerName=${containerName}&tutorialName=${fileName}&Tenant-Id=${FieldService.getTenantId()}`
  }

  delete = async rq => {
    return postToApi(this.name, 'Delete', rq)
  }

  list = async rq => {
    return postToApi(this.name, 'List', rq)
  }
}

class WeatherController {
  constructor() {
    this.name = 'Weather'
  }

  retrieve = async rq => {
    return postToApi(this.name, 'Retrieve', rq)
  }
}

const host = {
  activityLog: new ActivityLogController(),
  autoTaskRule: new AutoTaskRuleController(),
  billing: new BillingController(),
  billingGroup: new BillingGroupController(),
  calendar: new CalendarController(),
  commission: new CommissionController(),
  commissionType: new CommissionTypeController(),
  configuration: new ConfigurationController(),
  customerAlertType: new CustomerAlertTypeController(),
  customer: new CustomerController(),
  customerCustomInfo: new CustomerCustomInfoController(),
  customerPortal: new CustomerPortalController(),
  customerPortalSettings: new CustomerPortalSettingsController(),
  customerType: new CustomerTypeController(),
  customView: new CustomViewController(),
  document: new DocumentController(),
  email: new EmailController(),
  emailTemplate: new EmailTemplateController(),
  estimate: new EstimateController(),
  invoice: new InvoiceController(),
  measure: new MeasureController(),
  measureType: new MeasureTypeController(),
  office: new OfficeController(),
  onlinePayment: new OnlinePaymentController(),
  onlinePaymentAuth: new OnlinePaymentAuthController(),
  onlinePaymentSettings: new OnlinePaymentSettingsController(),
  photo: new PhotoController(),
  quickBooksOnline: new QuickBooksOnlineController(),
  quickBooksSettings: new QuickBooksSettingsController(),
  rate: new RateController(),
  recurringPayment: new RecurringPaymentController(),
  recurringPaymentFrequency: new RecurringPaymentFrequencyController(),
  recurringService: new RecurringServiceController(),
  referralType: new ReferralTypeController(),
  report: new ReportController(),
  salesTax: new SalesTaxController(),
  salesTaxGroup: new SalesTaxGroupController(),
  scheduledService: new ScheduledServiceController(),
  scheduledServiceSeason: new ScheduledServiceSeasonController(),
  scheduledTask: new ScheduledTaskController(),
  selectList: new SelectListController(),
  serviceable: new ServiceableController(),
  serviceableType: new ServiceableTypeController(),
  serviceCommissionRule: new ServiceCommissionRuleController(),
  serviceRouteAssignment: new ServiceRouteAssignmentController(),
  serviceRoute: new ServiceRouteController(),
  serviceRouteSite: new ServiceRouteSiteController(),
  serviceType: new ServiceTypeController(),
  session: new SessionController(),
  site: new SiteController(),
  siteCustomInfo: new SiteCustomInfoController(),
  subscription: new SubscriptionController(),
  sync: new SyncController(),
  syncSession: new SyncSessionController(),
  syncWith: new SyncWithController(),
  taskType: new TaskTypeController(),
  terminationType: new TerminationTypeController(),
  tranCode: new TranCodeController(),
  tranCodeGroup: new TranCodeGroupController(),
  transaction: new TransactionController(),
  transactionBatch: new TransactionBatchController(),
  treatment: new TreatmentController(),
  treatmentType: new TreatmentTypeController(),
  user: new UserController(),
  userGroup: new UserGroupController(),
  video: new VideoController(),
  weather: new WeatherController()
}

export { host }
