<template>
  <div>
    <v-dialog v-model="showConfirmDialog" max-width="400" persistent>
      <v-card width="400">
        <v-card-title>
          <DialogTitle>
            {{ title }}
          </DialogTitle>
        </v-card-title>
        <v-card-text>
          {{ message }}
        </v-card-text>
        <v-card-actions>
          <v-btn class="ml-3" @click="cancelClick">Cancel</v-btn>
          <v-spacer />
          <v-btn color="primary" @click="acceptClick">Accept</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-snackbar
      v-model="showErrorDialog"
      top
      color="red"
      :timeout="errorTimeout"
    >
      <div>
        <v-btn text @click="showErrorDialog = false">
          <div style="width: 100%">
            <span>{{ errorText }}</span>
            <span class="float-right">
              <v-icon right>mdi-close</v-icon>
            </span>
          </div>
        </v-btn>
      </div>
    </v-snackbar>
    <v-snackbar
      v-model="showInformDialog"
      top
      :color="informColor"
      :timeout="informTimeout"
    >
      <div>
        <v-btn text @click="showInformDialog = false">
          <div style="width: 100%">
            <span>{{ informText }}</span>
            <span class="float-right">
              <v-icon right>mdi-close</v-icon>
            </span>
          </div>
        </v-btn>
      </div>
    </v-snackbar>
  </div>
</template>

<script>
import { ref } from 'vue'
export default {
  name: 'DialogUtil',
  setup() {
    const showConfirmDialog = ref(false)
    const title = ref()
    const message = ref()
    var dialogResolver = null
    var dialogRejector = null

    var showErrorDialog = ref(false)
    var errorText = ref()
    var errorTimeout = ref(3000)

    var showInformDialog = ref(false)
    var informText = ref()
    var informColor = ref()
    var informTimeout = ref(3000)

    const confirm = args => {
      title.value = args.title ?? 'Please confirm'
      message.value = args.message
      showConfirmDialog.value = true
      return new Promise((resolve, reject) => {
        dialogResolver = resolve
        dialogRejector = reject
      })
    }

    const error = message => {
      errorText.value = message
      showErrorDialog.value = true
    }

    const inform = args => {
      if (typeof args == 'string' || args instanceof String) {
        informText.value = args
      } else {
        informText.value = args.text ?? 'fyi'
        informColor.value = args.color ?? 'success'
        informTimeout.value = args.timeout ?? 3000
      }

      showInformDialog.value = true
    }

    const acceptClick = async () => {
      showConfirmDialog.value = false
      dialogResolver(true)
    }

    const cancelClick = () => {
      showConfirmDialog.value = false
      dialogRejector(new Error('cancel'))
    }

    return {
      confirm,
      error,
      inform,
      title,
      message,
      showConfirmDialog,
      showErrorDialog,
      errorText,
      errorTimeout,
      showInformDialog,
      informText,
      informColor,
      informTimeout,
      acceptClick,
      cancelClick
    }
  }
}
</script>

<style lang="scss" scoped></style>
