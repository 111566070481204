import icon from '@/constants/Icons'

export default {
  home: {
    title: 'Home',
    routerName: 'home',
    color: 'green darken-2',
    icon: icon.site
  },
  dashboard: {
    title: 'Dashboard',
    routerName: 'dashboard',
    color: 'black',
    icon: icon.dashboard
  },
  sendEmail: {
    color: 'purple',
    dialogWidth: 800,
    icon: icon.email
  },
  profile: {
    title: 'Profile',
    singularTitle: 'Profile',
    routerName: 'profile',
    dialogWidth: 1250,
    color: 'blue',
    icon: icon.customer,
    statusColors: {
      A: 'green--text',
      I: 'red--text',
      P: 'red--text',
      T: 'green--text',
      B: 'red--text'
    }
  },
  invoice: {
    color: 'purple'
  },
  maps: {
    toolTip: 'View Map',
    dialogWidth: 1000,
    color: 'red',
    icon: icon.map
  },
  services: {
    title: 'Services',
    singularTitle: 'Services',
    routerName: 'services',
    dialogWidth: 1025,
    color: 'purple',
    icon: icon.scheduledService,
    iconNewService: icon.addNew,
    optimizeDialogWidth: 820
  },
  recurringServices: {
    title: 'Recurring Services',
    singularTitle: 'Recurring Service',
    color: 'purple',
    icon: icon.recurringService
  },
  serviceables: {
    title: 'Serviceables',
    singularTitle: 'Serviceable',
    routerNameEdit: 'serviceable-edit',
    routerNameAdd: 'serviceable-add',
    icon: icon.serviceable,
    color: 'purple'
  },
  reports: {
    title: 'Reports',
    routerName: 'reports',
    color: 'pink',
    colorParameter: 'pink lighten-2',
    icon: icon.report
  },
  billing: {
    title: 'Billing',
    singularTitle: 'Billing',
    routerName: 'billing',
    color: 'green',
    icon: icon.billing
  },
  payments: {
    title: 'Payments',
    singularTitle: 'Payments',
    color: 'red',
    icon: icon.payment
  },
  sites: {
    title: 'Sites',
    routerNameEdit: 'site-edit',
    routerNameAdd: 'site-add',
    color: 'green darken-2',
    icon: icon.site,
    iconEdit: icon.siteEdit,
    iconAdd: icon.siteAdd,
    dialogWidth: 1000,
    cardWidth: 400,
    cardBackgroundColor: 'light-green lighten-4'
  },
  onlinePayments: {
    title: 'Online Payments',
    singularTitle: 'Online Payment',
    color: 'green',
    icon: icon.onlinePayment,
    iconNewOnlinePayment: icon.addNew,
    iconRecurringPayment: icon.onlinePaymentRecurring,
    iconAuth: icon.onlinePaymentAuth
  }
}
