<template>
  <div>
    <v-card class="my-2">
      <v-card-text class="d-flex">
        <div>
          <div>Balance: {{ toCurrency(cardData.AgingSummary.Balance) }}</div>
          <div v-if="cardData.AgingSummary.A30 != 0">
            Over 30 days: {{ toCurrency(cardData.AgingSummary.A30) }}
          </div>
          <div v-if="cardData.AgingSummary.A60 != 0" class="red--text">
            Over 60 days: {{ toCurrency(cardData.AgingSummary.A60) }}
          </div>
          <div
            v-if="cardData.AgingSummary.A90 != 0"
            class="red--text font-weight-bold"
          >
            Over 90 days: {{ toCurrency(cardData.AgingSummary.A90) }}
          </div>
        </div>
        <v-spacer></v-spacer>
        <div
          v-if="
            !disableBillingLink &&
              portalSettings &&
              portalSettings.ShowTransactions
          "
          class="d-flex"
        >
          <v-tooltip top>
            <template v-slot:activator="{ on, attrs }">
              <v-btn v-bind="attrs" v-on="on" icon small @click="billingClick">
                <v-icon :color="feature.billing.color">
                  {{ feature.billing.icon }}
                </v-icon>
              </v-btn>
            </template>
            <span>Go to Billing</span>
          </v-tooltip>
        </div>
      </v-card-text>
    </v-card>
  </div>
</template>

<script>
import { useRouter } from 'vue2-helpers/vue-router'
import { toCurrency } from '@/use/NumberFormatter'
import { icon, feature } from '@/use/Constants'
import {
  retrievePortalSettings,
  portalSettings
} from '@/services/PortalSettingsCache'
export default {
  name: 'BillingSummaryCard',
  props: {
    cardData: {
      type: Object,
      required: true
    },
    disableBillingLink: {
      type: Boolean,
      default: false
    }
  },
  setup(props) {
    const router = useRouter()

    const billingClick = () => {
      router.push({
        name: feature.billing.routerName,
        params: { id: props.cardData.CustomerId }
      })
    }

    retrievePortalSettings()

    return {
      billingClick,
      portalSettings,
      toCurrency,
      icon,
      feature
    }
  }
}
</script>

<style lang="scss" scoped></style>
