import { ref } from 'vue'
import { host } from '@/services/HostAPI'
import FieldService from '@/services/FieldService'

const localStorageKey = 'customer'
const localStorageVersion = 'v2'
const userNameNotLoggedIn = 'Not Logged In'

const loggedOutState = {
  Version: localStorageVersion,
  ApiKey: null,
  OfficeInfo: {
    OfficeId: null,
    OfficeCode: null,
    OfficeName: ''
  },
  UserInfo: {
    LoginName: null,
    UserName: userNameNotLoggedIn,
    isSysAdmin: false
  }
}

const sessionState = ref(loggedOutState)
var isLoggedIn = ref(false)
const mustChangePassword = ref(false)

const login = async credentials => {
  const rq = credentials
  const rs = await host.session.customerLogin(rq)

  if (rs.IsSuccess) {
    FieldService.setAuthToken(rs.ApiKey)
    sessionState.value.UserInfo = rs.UserData
    mustChangePassword.value = rs.MustChangePassword
    isLoggedIn.value = true
    saveToLocalStorage(sessionState.value)
  }

  return { IsSuccess: rs.IsSuccess, Message: rs.Message }
}

const logout = () => {
  localStorage.clear()
  isLoggedIn.value = false
  sessionState.value.loggedOutState
}

const propOfficeCode = ref()

const saveToLocalStorage = () => {
  const storageValue = JSON.stringify({
    sessionState: sessionState.value
  })
  localStorage.setItem(localStorageKey, storageValue)
}

export {
  sessionState,
  login,
  logout,
  isLoggedIn,
  propOfficeCode,
  mustChangePassword
}
