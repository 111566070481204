import { host } from '@/services/HostAPI'
import { ref } from 'vue'

const portalSettings = ref()
const isCacheValid = ref(false)

const retrievePortalSettings = async () => {
  if (!isCacheValid.value) {
    let rs = await retrieveCustomerPortalSettings()
    portalSettings.value = rs.Info
    isCacheValid.value = true
    setTimeout(() => {
      isCacheValid.value = false
    }, 20000)
  }
  return portalSettings.value
}

const retrieveCustomerPortalSettings = async () => {
  const rq = {}
  const rs = await host.customerPortalSettings.retrieve(rq)
  return rs
}

export { isCacheValid, retrievePortalSettings, portalSettings }
