<template>
  <div v-if="cardData">
    <v-card width="400px" class="pt-2 pl-3 ma-2 indigo lighten-5">
      <v-card-title>
        <span>{{ cardData.Name }}</span>
      </v-card-title>
      <v-card-subtitle :class="statusClass">
        <div>
          <span>{{ cardData.StatusDescription }}</span>
          <span class="float-right black--text mr-2">{{ cardData.Code }}</span>
        </div>
      </v-card-subtitle>
      <v-card-text>
        <AddressCard
          :name="cardData.Address.Name"
          :street="cardData.Address.Street"
          :city="cardData.Address.City"
          :state="cardData.Address.State"
          :postCode="cardData.Address.PostCode"
        />
        <v-card v-if="cardData.PhoneList.length > 0">
          <v-card-text>
            <div v-for="phone in cardData.PhoneList" v-bind:key="phone.Id">
              {{ phone.PhoneNumberType }} : {{ phone.FormattedPhone }}
            </div>
          </v-card-text>
        </v-card>
        <BillingSummaryCard
          :cardData="cardData"
          :readonly="readonly"
          @update="billingSummaryCardUpdateEvent"
        />
        <OnlinePaymentCard
          :cardData="cardData"
          :readonly="readonly"
          @update="onlinePaymentCardUpdateEvent"
        />
      </v-card-text>
    </v-card>
  </div>
</template>

<script>
import { ref, computed } from 'vue'
import { feature } from '@/use/Constants'
import AddressCard from '@/components/Common/AddressCard'
import BillingSummaryCard from '@/components/Billing/BillingSummaryCard'
import OnlinePaymentCard from '@/components/OnlinePayment/OnlinePaymentCard'

export default {
  name: 'CustomerCard',
  components: {
    AddressCard,
    BillingSummaryCard,
    OnlinePaymentCard
  },
  props: {
    cardData: {
      type: Object,
      default: null
    },
    readonly: {
      type: Boolean,
      default: false
    }
  },
  setup(props, context) {
    const instanceValue = ref(0)

    const statusClass = computed(() => {
      return feature.profile.statusColors[props.cardData.Status]
    })

    const billingSummaryCardUpdateEvent = () => {
      context.emit('update')
    }

    const onlinePaymentCardUpdateEvent = () => {
      context.emit('update')
    }

    return {
      feature,
      statusClass,
      instanceValue,
      billingSummaryCardUpdateEvent,
      onlinePaymentCardUpdateEvent
    }
  }
}
</script>

<style lang="scss" scoped></style>
