import CloudManagerService from '@/services/CloudManagerService'

const execute = async (controllerName, operationName, rq) => {
  const rs = await CloudManagerService.doPost(
    `${controllerName}/${operationName}`,
    rq
  )
  return rs.data
}

class OfficeController {
  constructor() {
    this.name = 'Office'
  }

  retrieve = async rq => {
    return execute(this.name, 'Retrieve', rq)
  }

  list = async rq => {
    return execute(this.name, 'List', rq)
  }
}

const cloudManager = {
  office: new OfficeController()
}

export { cloudManager }
