<template>
  <div>
    <div class="d-flex">
      <div class="mt-3 ml-2 mr-2">
        <TutorialButton :tutorialName="$options.name" />
      </div>
    </div>
    <div
      v-if="formData"
      class="mt-3 ml-4 mr-2"
      style="min-height: 300px;"
      v-html="formData.Info.HomePageText"
    />
    <v-footer>
      <span class="caption">Copyright © 2022 ArrowStorm Corporation</span>
    </v-footer>

    <v-dialog v-model="showChangePasswordDialog" width="500">
      <ChangePasswordEditor
        :isDialogOpen="showChangePasswordDialog"
        @close="changePasswordCloseEvent"
      />
    </v-dialog>
  </div>
</template>

<script>
import { ref } from 'vue'
import { host } from '@/services/HostAPI'
import { mustChangePassword, sessionState } from '@/store/UserSession'
import ChangePasswordEditor from '@/components/User/ChangePasswordEditor'
import TutorialButton from '@/components/controls/TutorialButton'
export default {
  name: 'HomePage',
  components: {
    ChangePasswordEditor,
    TutorialButton
  },
  setup() {
    const formData = ref()
    const showChangePasswordDialog = ref(false)

    const showConfirmDialog = ref(false)

    const initForm = async () => {
      const rq = {}
      const rs = await host.customerPortal.retrieveHomePageInfo(rq)
      formData.value = rs
      if (mustChangePassword.value) {
        showChangePasswordDialog.value = true
      }
    }

    const changePasswordCloseEvent = () => {
      showChangePasswordDialog.value = false
    }

    initForm()

    return {
      sessionState,
      formData,
      showChangePasswordDialog,
      changePasswordCloseEvent,
      showConfirmDialog
    }
  }
}
</script>

<style lang="scss" scoped></style>
